
import React, { useState, useRef, useEffect } from 'react';
import './App.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperclip, faPaperPlane, faXmarkCircle, faMicrophone} from '@fortawesome/free-solid-svg-icons';

function ImagePreview({images, removeImage, imagesPreviewRef, isDarkMode, chatMessagesRef}) {

    useEffect(()=>{
        console.log('component reloaded')
        if (images.length > 0) {
            imagesPreviewRef.current.style.display = 'flex'
        } else {
            imagesPreviewRef.current.style.display = 'none'
        }
    },[images])

    return (
        <div className={`imagesPreviewContainer ${isDarkMode ? 'ipc-dark' : ''}` }ref={imagesPreviewRef}>

            {images && images.map((img, idx) => {
                return (
                <div className='tmp-container'>
                <div className='displayed-img-container' key={idx}>
                        <button className={'close-button-small'} onClick={()=>removeImage(idx)}><span><FontAwesomeIcon icon={faXmarkCircle} style={{color: 'red', fontSize: '20pt'}}/></span></button>
                        <img src = {img} style={{maxWidth: '180px', maxHeight: '180px'}}></img>
                </div>
                </div>
                );
            })}

        </div>
    );
}



function CustomTextInput({handleSendMessage, handleImageChange, handleToggleAudio, isRecordingAudio, textInputRef, chatMessagesRef, imagesPreviewRef, attachedImgs, removeImage, handlePaste, isDarkMode, setMessagesHeight, isTranscribingAudio, questionBeingAnswered, setQuestionBeingAnswered, isMobile, replaceByLongDash}) {
    const formattingPopupRef = useRef(null);
    const hiddenUploadFileInput = useRef(null);
    const micButtonRef = useRef(null);

    const [startTime, setStartTime] = useState(null);
    const [elapsedTime, setElapsedTime] = useState(null);
    const [isFocused, setIsFocused] = useState(null);

    useEffect(()=>{
        let interval = null;
        if (isRecordingAudio) {
            setStartTime(new Date());            
            interval = setInterval(()=>{
                setElapsedTime(new Date());
            }, 1000)
        } else {
        }

        return () => {
            clearInterval(interval);
            setStartTime(null);
            setElapsedTime(null);
        }
    }, [isRecordingAudio])




    const formatTime = () => {
        if (!startTime || !elapsedTime) {
            return '';
        }

        const elapsed = new Date(elapsedTime - startTime);
        const minutes = elapsed.getUTCMinutes().toString().padStart(2, '0');
        const seconds = elapsed.getUTCSeconds().toString().padStart(2, '0');
        return `${minutes}:${seconds}`;
    };
    
    const insertTextAtCursor = (text) => {
        const sel = window.getSelection();
        if (sel.rangeCount) {
            const range = sel.getRangeAt(0);
            const cp =  range.startOffset + text.length - 1;                
            
            range.deleteContents();
            range.insertNode(document.createTextNode(text)); // inserts text
            range.collapse(false); // move the cursor at the end of the inserted text, the focus leaves the input
            sel.removeAllRanges();
            sel.addRange(range); // the selection is added (cursor visible on the input bar) 

        }
    }


    const addMessage = () => {
        const text = textInputRef.current.innerHTML;  
        handleSendMessage(text);
        textInputRef.current.innerText = '';
        // sets the height back after writing a long message
        setTimeout(()=>setMessagesHeight(), 100);
    }

    const handleKeyDown = (e) => {
        let insertText = '';

        if (e.key === 'Enter' && e.shiftKey) {
            e.preventDefault();
            addMessage();
            return;

        } else if (e.ctrlKey && e.key === 'v') {
            // e.preventDefault();
            // console.log('ctrl v')
            return;
        }
        
        
        else if (e.altKey) {            
            e.preventDefault();
            
            switch (e.key){
                case 't':
                    insertText = '#thought: ';
                    break;
                case 'g':
                    insertText = '#I\'m grateful for: ';
                    break;
                default:
                    return;
            }
            insertTextAtCursor(insertText);
        }

        replaceByLongDash(textInputRef);

    };
   


    const handleTextSelect = () => {
        // setting message height each time a selection is made (input bar interaction e.g. paste)
        setMessagesHeight();

        const selection = window.getSelection();
        if (selection.toString().length > 0) {
            // Position and show the formatting popup
            const range = selection.getRangeAt(0);
            const rect = range.getBoundingClientRect();
            const rectTextBox = textInputRef.current.getBoundingClientRect();
            const formattingPopup = formattingPopupRef.current;

            formattingPopup.style.display = 'block';
            // offset height of the selection, 43px ~ a line. 
            formattingPopup.style.top = `${Math.min(rect.y, rectTextBox.y) + window.scrollY - formattingPopup.offsetHeight}px`;
            formattingPopup.style.left = `${rect.x + window.scrollX}px`;
        }
        else {
            const formattingPopup = formattingPopupRef.current;
            formattingPopup.style.display = null;
        }
    };

    const applyFormat = (formatType) => {
        document.execCommand(formatType);
    };
    
    const onRemoveReplyQ = () => {
        setQuestionBeingAnswered('');
    }

    return (
        <>
        <div>
                <div className={`formatting-popup-inputbar ${isDarkMode ? 'fpm-dark' : ''}`} ref={formattingPopupRef}>
                    <button className='button-bold' onClick={() => {applyFormat('bold');}}><b>B</b></button>
                    <button className='button-bold' onClick={() => applyFormat('italic')}><i>I</i></button>
                {/* Add more formatting buttons as needed */}
                </div>
        </div>

        <div className='input-containers-rows-wrapper'>
            <ImagePreview images={attachedImgs} removeImage={removeImage} imagesPreviewRef={imagesPreviewRef} chatMessagesRef={chatMessagesRef} isDarkMode={isDarkMode}></ImagePreview>
            <div className='input-container-second-row'>
                {questionBeingAnswered && 
                <>
                <div className={`title-and-buttons ${isDarkMode ? 'mrac-dark' : 'message-reply-and-cross'}`}>
                <div>{questionBeingAnswered}</div>
                    <button onClick={onRemoveReplyQ} className='small-remove-button-QA'><FontAwesomeIcon icon={faXmarkCircle}/></button>
                </div>
                </>
                }
            </div>
            <div className='input-container-row-bottom' style={isDarkMode ? {backgroundColor: 'black', color: 'white'} : {}}>

                {!isFocused && !isRecordingAudio && <button className='button-inputBar' onClick={()=>hiddenUploadFileInput.current.click()}><span><FontAwesomeIcon icon={faPaperclip}/></span></button>}
                <input
                    type='file'
                    onChange={handleImageChange}
                    accept="image/*" 
                    style={{display: 'none'}}
                    ref={hiddenUploadFileInput}
                ></input>

                {!isFocused && 
                        <button className={`button-inputBar ${isRecordingAudio ? 'recording-button' : ''}`} onClick={handleToggleAudio}>
                            <span>
                                {isRecordingAudio ? formatTime():<FontAwesomeIcon icon={faMicrophone}/>}
                            </span></button>
                    
                } 
                
                {!isRecordingAudio && 
                <>
                <div 
                    className={isDarkMode ?  "text-input dark-text-input" : 'text-input'} 
                    ref={textInputRef}
                    contentEditable="true" 
                    role="textbox" 
                    aria-multiline="true"
                    onKeyDown={handleKeyDown}
                    onSelect={handleTextSelect}
                    onPaste={handlePaste}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                    >
                </div>
                <button className='button-inputBar' onClick={addMessage}><span><FontAwesomeIcon icon={faPaperPlane}/></span></button>
                </>}
            </div>
        </div>
        </>
    );
}


export default CustomTextInput;



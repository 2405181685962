import React, { useState, useEffect, useRef, createRef } from 'react';
import ReactModal from 'react-modal';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { GoogleLogin } from '@react-oauth/google';
import { googleLogout } from '@react-oauth/google';
import { Buffer } from 'buffer';
import parse from 'html-react-parser';
import {VariableSizeList as List} from 'react-window';
import io from 'socket.io-client';
import './App.css'
import CustomTextInput from './inputbar.js'
import CryptoJS from 'crypto-js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCog, faPencilAlt, faTrashCan, faPaperclip, faMagnifyingGlass, faArrowDown, faInfo, faHome, faFilter, faPaperPlane, faComment, faXmark, faTimes, faCheck, faFeather, faBookBookmark, faPerson, faPlus, faTriangleExclamation, faKey, faFloppyDisk, faCircleXmark, faCircleQuestion, faPersonWalkingArrowRight, faPersonWalkingArrowLoopLeft, faAngleDoubleDown, faAngleDoubleUp, faLink, faWandMagicSparkles, faQuestion, faArrowRight, faArrowLeft, faRepeat, faChartSimple, faTicket, faCircleCheck, faEllipsisVertical, faCalendarDays, faCake, faRotateRight, faCircleInfo, faPersonCirclePlus } from '@fortawesome/free-solid-svg-icons';


function convertDateStringToObject(dateStr) {
    // Split the date string by '-'
    const parts = dateStr.split('-');

    // parts[0] is the month, parts[1] is the day, parts[2] is the year
    // Note: the month is 0-indexed in JavaScript Date (0 = January, 11 = December)
    const month = parseInt(parts[0], 10) - 1;
    const day = parseInt(parts[1], 10);
    const year = parseInt(parts[2], 10);

    // Create a new Date object
    return new Date(year, month, day);
}

function getCurrentDateFormatted() {
    const date = new Date();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-based in JavaScript
    const day = date.getDate().toString().padStart(2, '0');
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
  
    return [`${month}-${day}-${year}`, `${hours}:${minutes}`];
  }



// const root_server_url = "http://192.168.1.229:5000" 
const root_server_url = "https://mindprompt.net"
// const root_server_url = 'https://9181-139-47-126-136.ngrok-free.app'
// const root_server_url = "http://localhost:5000" 
const fetch_url = root_server_url + "/api"

function formatDateObjToString(date) {
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // getMonth() is zero-based
    const day = date.getDate();

    // Pad the month and day with a leading zero if they are less than 10
    const formattedMonth = month < 10 ? `0${month}` : month;
    const formattedDay = day < 10 ? `0${day}` : day;

    return `${year}-${formattedMonth}-${formattedDay}`;
}

function formatDateObjToStringMDY(date) {
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // getMonth() is zero-based
    const day = date.getDate();

    // Pad the month and day with a leading zero if they are less than 10
    const formattedMonth = month < 10 ? `0${month}` : month;
    const formattedDay = day < 10 ? `0${day}` : day;

    return `${formattedMonth}-${formattedDay}-${year}`;
}

function ImageComponent({imageName, token, handleClick, maxResolution}) {
    const imageUrl = `${fetch_url}/images/${imageName}?token=${token}`

    const upscaleImage = handleClick ? true : false;
    return <img src={imageUrl} className='img-displayed-in-message' style = {maxResolution ? {maxHeight: maxResolution, maxWidth: maxResolution} : {}}onClick={upscaleImage ? ()=>handleClick(imageUrl) : ()=>{}}/>
}

function AudioEmbed({audioName, token}) {
    const audioUrl = `${fetch_url}/audio/${audioName}?token=${token}`
    return (
        <div style={{padding: '20px'}}>
        <audio controls={true}>
            <source src={audioUrl} type='audio/mpeg'/>
        </audio>
        </div>
    );
};


function SliderSwitch({boolStateVar, switchStateVarSetter, children}) {
    const [isEnabled, setIsEnabled] = useState(boolStateVar);


    useEffect(()=>{
        switchStateVarSetter(isEnabled);
    },[isEnabled])

    const toggleSwitch = () => setIsEnabled(ps=>!ps); 

    return (
        <div className='slider-container'>
        {children && <span className='slider-label'>{children}</span>}
        <label className='toggle-switch'>
            <input
                type='checkbox'
                checked={isEnabled}
                onChange={toggleSwitch}
            />
            <span className='slider round'></span>
        </label>
        </div>
    );
}


function ButtonWithTooltip({buttonText, hoverText, onClick}) {
  return (
      <button className="tooltip-button" onClick={onClick}>{buttonText}
        <span className="tooltip-text">{hoverText}</span>
      </button>
  );
}



function LanguageSelector ({languagesOptions, selectedLanguage, setLanguageState, isDarkMode, children}) {

    const handleSelectionChange = (e) => {
        setLanguageState(e.target.value);
        }
    
    return (
        <div className='paralel-label-input'>
            <label>
                {children}            
            </label>
        <select
            id = 'language-selector'
            className= {isDarkMode ? 'input-dark' : ''}
            value={selectedLanguage}
            onChange={handleSelectionChange}
        >
            {languagesOptions.map((v, idx) => (
                <option key={idx} value={v}>
                    {v}
                </option>
            ))
            }

        </select>

        </div>

    );
}

function ResultObject({result, go_to_entry, isDarkMode}) {
    
    return (
        <div className={`search-result ${isDarkMode ? 'sr-dark' : '' }`} key={result.p_id} onClick={()=>go_to_entry(result.p_id)}>
            <p>{`${result.date}, ${result.time}`}</p>
            <p style={{padding: '5px'}}>{parse(result.text)}</p>
        </div>
    );
}

function SearchResults({results, go_to_entry, isDarkMode}) {
    return (
        <div className={`search-results-container ${isDarkMode ? 'sr-container-dark' : ''}`}>
            {results.map((item, idx) => (
                <ResultObject result={item} go_to_entry={go_to_entry} isDarkMode={isDarkMode}/>
            ))
            }
        </div>
    );
}



function SearchBarMessages({getSearchResults, searchTerm, setSearchTerm, setIsSearchingToday, isSearchingToday, IsLoadingEmbeddingSearch, setSearchResultsList, isDarkMode, go_to_date, isModalAdvancedSearchVisible_msg, setIsModalAdvancedSearchVisible_msg, availableDatesListRefMsg, fetch_dates_msg, filterSearchSelectedTimeDelta, setFilterSearchSelectedTimeDelta}) {

    const refInput = useRef(null);
    const searchBarRef = useRef(null);
    
    const [selectedGoDate, setSelectedGoDate] = useState('null');

    const handleSearchChange = (e) => {
        console.log('change!')
        setSearchTerm(e.target.value);
        setIsSearchingToday(true);
        // current attempt here: 
        searchBarRef.current.style.maxWidth = 'none';
    };

    const onKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.target.blur();
            getSearchResults(searchTerm);
        }
    }

    const onIsCancel = () => {
        setIsSearchingToday(false);
        searchBarRef.current.style.maxWidth = '';
        refInput.current.blur();
        setSearchTerm('');
        // set Results to zero to hide the search results
        setSearchResultsList([]);
        
    }

    const onAdvancedSearchClick = () => {
        setIsModalAdvancedSearchVisible_msg(!isModalAdvancedSearchVisible_msg);
    }

    const onMouseLeaveAS = (e) => {
        setIsModalAdvancedSearchVisible_msg(false);
    }

    const onChangeSelectFilterDateTimeDelta = (e) => {
        setFilterSearchSelectedTimeDelta(e.target.value);
    }

    return (
        <>
            <div className={`search-bar ${isDarkMode ? 'sb-dark' : ''}`} ref={searchBarRef}>
                <input
                    type="text"
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={handleSearchChange}
                    onKeyDown={onKeyDown}
                    className={`search-input ${isDarkMode ? 'sb-dark' : ''}`}
                    ref={refInput}
                />
                {isSearchingToday && <button className={`search-button ${isDarkMode ? 'sb-dark' : ''}`} onClick={onIsCancel}><span><FontAwesomeIcon icon={faXmark}/></span></button>}
                <button className={`search-button ${isDarkMode ? 'sb-dark' : ''}`} onClick={() => getSearchResults(searchTerm)} ><span><FontAwesomeIcon icon={faMagnifyingGlass}/></span></button>
                {/* <button><FontAwesomeIcon icon={faCalendarDays}/></button> */}
                <MyDatepicker
                        availableDatesListRefMsg={availableDatesListRefMsg} fetch_dates_msg={fetch_dates_msg}  isDarkMode={isDarkMode} goToDate={go_to_date} tag={'messages'}
                />
                <button className={`search-button ${isDarkMode ? 'sb-dark' : ''}`} onClick={onAdvancedSearchClick}><span><FontAwesomeIcon icon={faEllipsisVertical}/></span></button>
                {IsLoadingEmbeddingSearch && <div className='spinner'></div>}
            </div>
            <ReactModal
            className={`advanced-search-modal ${isDarkMode ? 'asm-dark' : ''}`}
            overlayClassName = 'modal-overlay-dark'
            // overlayClassName='advanced-search-overlay'  
            isOpen={isModalAdvancedSearchVisible_msg}
            onRequestClose={()=>setIsModalAdvancedSearchVisible_msg(false)}
            >        
            <h4>Search settings</h4>
            <div className='asc-content'>
                <div className='input-button-modal'>
                    <label>Select a time filter for the search query</label>
                    <select onChange={onChangeSelectFilterDateTimeDelta} 
                    value={filterSearchSelectedTimeDelta}
                    className={isDarkMode ? 'input-dark' : ''}>
                        <option value={'today'}>today</option>
                        <option value={'last_week'}>Last week</option>
                        <option value={'last_month'}>Last month</option>
                        <option value={'last_quarter'}>Last quarter</option>
                        <option value={'last_year'}>Last year</option>
                        <option value={'all_time'}>All time</option>
                     </select>
                </div>
            </div>
            </ReactModal>
        </>
    );
}


const ModelComponent = ({idx, model, editModel, removeModel, isDarkMode, replaceByLongDash}) => {
    const [editMode, setEditMode] = useState(false); 
    const [aliasList, setAliasList] = useState(model.alias || []);
    const [editingName, setEditingName] = useState(model.name || '');
    const descriptionTextRef = useRef(null);
    const [currentAlias, setCurrentAlias] = useState('');
    const [selectedDescriptionDate, setselectedDescriptionDate] = useState(model.desc_archive ? Object.keys(model.desc_archive)[0] : formatDateObjToStringMDY((new Date())));

            
    const buttons = <><button onClick={()=>setEditMode(ps=>!ps)}><span><FontAwesomeIcon icon={faPencilAlt}/></span></button> 
                        <button onClick = {()=>removeModel(model.id)}><span><FontAwesomeIcon icon={faTrashCan}/></span></button> 
                    </>
    
    const handleNameChange = (e) => setEditingName(e.target.value);
    const handleAliasChange = (e) => setCurrentAlias(e.target.value);
    
    const addAliasToList = (text) => {
        const alias = [...aliasList, text];
        setCurrentAlias('');
        const seto = new Set(alias);
        setAliasList([...seto]);
    }

    const confirmEditModel = () => {
        const newModel = {name: editingName, alias: aliasList, description: descriptionTextRef.current.textContent};
        editModel(model.id, newModel);
        setEditMode(false);
        descriptionTextRef.current.textContent = '';
    };

    const onChangeSelection = (e) => 
        {
        setselectedDescriptionDate(e.target.value);
        console.log(e.target.value);
        }

    const simpleHandleKeyDown = (e) => {
        replaceByLongDash(descriptionTextRef);
    }
    
    return (
    <div className='model-container'> 
        <div className='model-item'>
            {!editMode && 
            <>
            <h3>{model.name}</h3>
            <p><b>Alias: </b>{'◼️' + model['alias'].join('◼️')}</p> 
            {model.desc_archive ? (         
                <div className='description'>            
                    <span> <b>Description date: </b></span>
                    {Object.keys(model.desc_archive).length > 1 ? (
                        <select 
                        onChange={onChangeSelection}
                        value={selectedDescriptionDate}
                        >
                            {Object.keys(model.desc_archive).map((k) => <option key={k} value={k}>{k}</option>)
                            }
                        </select>
                        ) : <span>{Object.keys(model.desc_archive)[0]}</span>
                    }
                
                    <h4>Description:</h4>
                    <p>{model.desc_archive[selectedDescriptionDate]}</p>
                </div>
                ) : (
                    <div>
                        <h4>Description:</h4>
                        <p>{model.description}</p>
                    </div>
                )    
            }
            </>
            }
        </div>
        {editMode &&
        
            <div>
                <h4>Full name:</h4>
                <input type='text' 
                className= {isDarkMode ? 'input-dark' : ''} 
                placeholder='Enter full name' 
                value={editingName} 
                onChange={handleNameChange}/>

                <div>
                    <h4>Alias:</h4>
                        <input type='text'
                        className= {isDarkMode ? 'input-dark' : ''} 
                        value={currentAlias}
                        onChange={handleAliasChange}
                        placeholder='enter new alias'/>
                    <button onClick={()=>addAliasToList(currentAlias)}>Add alias</button>
                    <button onClick={() => setAliasList([])}><FontAwesomeIcon icon={faTrashCan}/></button>
                    <p style={{fontSize: '0.95em'}}>Alias: { aliasList.join(', ')}</p>
                    
                </div>

                <h4>Description:</h4>
                    <div
                        className={`input-description ${isDarkMode ? 'input-dark' : ''}`}
                        contentEditable='true'
                        role='textbox'
                        aria-multiline='true'
                        onKeyDown={simpleHandleKeyDown}
                        ref={descriptionTextRef}
                    >{model.description}</div>              

                    <button onClick={confirmEditModel}><FontAwesomeIcon icon={faFloppyDisk}/></button>
                    <button onClick={()=>setEditMode(false)}><FontAwesomeIcon icon={faCircleXmark}/></button>
            </div>    
        }

        {!editMode && buttons}
        {/* <hr/> */}
    </div>
    );
}

function DatePickerMessage({init_date, init_time, setIsEditingDate, isDarkMode, setDateOnMessage}) {
    const [selectedDate, setSelectedDate] = useState(convertDateStringToObject(init_date));
    const [selectedTime, setSelectedTime] = useState(init_time);
    
    const onChangeTime = (e) => {
        setSelectedTime(e.target.value);
        const time = e.target.value;
        console.log(time.trim());
    }


    const onClickOk = () => {
        setDateOnMessage(formatDateObjToStringMDY(selectedDate), selectedTime);
        setIsEditingDate(false);
    }

    return(
        <div className='editing-time-and-date-fields'>
            <DatePicker
                selected={selectedDate}
                onChange={date => setSelectedDate(date)}
                className={`calendar-date-editor ${isDarkMode ? 'input-dark' : ''}`}
            />
            <input className={`time-editor ${isDarkMode ? 'input-dark' : ''}`} type='time' value={selectedTime} onChange={onChangeTime}/>   
            <button onClick={onClickOk} className='edit-message-small-buttons' style={{color: 'green'}}><FontAwesomeIcon icon={faCircleCheck}/></button>
            <button className='edit-message-small-buttons' onClick={()=> setIsEditingDate(false)} style={{color: 'red'}}><FontAwesomeIcon icon={faCircleXmark}/></button>        

        </div>
    );
}

function MyDatepicker({availableDatesListRefMsg, tag, fetch_dates_msg, isDarkMode, goToDate}) {

    const myCalendarRef = useRef(null);
    const isInitialLoad = useRef(true);
    const [isCalendarOpen, setIsCalendarOpen] = useState(false);

    useEffect(() => {
        setTimeout(()=> myCalendarRef.current && myCalendarRef.current.setOpen(isCalendarOpen), 0);
    }, [isCalendarOpen])

    let date;
    const idx = tag === 'archive' ? 0 : availableDatesListRefMsg.current.length - 1;
    date = availableDatesListRefMsg.current[idx] ? new Date(availableDatesListRefMsg.current[idx]) : new Date();
    
    const [selectedObjDate, setSelectedObjDate] = useState(date);


    const await_setting_dates = async () => {
        await fetch_dates_msg();
        const idx = tag === 'archive' ? 0 : availableDatesListRefMsg.current.length - 1;
        const last_date_on_arch = availableDatesListRefMsg.current[idx];
        setSelectedObjDate(new Date(last_date_on_arch));
    }

    useEffect(()=>{
    // fetch available dates
    if (availableDatesListRefMsg.current.length === 0) {
        await_setting_dates();
    }
    },[])

    const onChangeSelection = (date) => {
        const string_date = formatDateObjToString(date);
        setIsCalendarOpen(false);
        goToDate(string_date);
    }

    const isDaySelectable = (date) => {        
        const date_string = formatDateObjToString(date);
        if (availableDatesListRefMsg.current.includes(date_string)) {
            return true;
        }
        return false;
    };

    const onClickButton = () => {
        setIsCalendarOpen(true);
    }

    const onCalendarCloses = () => {
        setIsCalendarOpen(false);
        console.log('setting modal down bc closed');
    };

    return (
        <div>
            <ReactModal
            isOpen={isCalendarOpen}
            onRequestClose={()=>setIsCalendarOpen(false)}
            className={`calendar-holder-modal ${isDarkMode ? 'asm-dark' : ''}`}
            overlayClassName = 'modal-overlay-dark'
            >
                <div className='calendar-container'>
                    <div className='popUpCalendar-container'>
                    <DatePicker
                        className = {isDarkMode ? 'dark-mode' : ''}
                        ref = {myCalendarRef}
                        // className={`calendar-date-editor-modal ${isDarkMode ? 'input-dark' : ''}`}
                        selected = {selectedObjDate}
                        onChange = {onChangeSelection}
                        filterDate = {isDaySelectable}
                        onCalendarClose = {onCalendarCloses}
                        customInput = {<button style={{display: 'none'}}></button>}
                    />
                    </div>

                </div>
            </ReactModal>

        <button className={`search-button ${isDarkMode ? 'sb-dark' : ''}`}  onClick={onClickButton}><FontAwesomeIcon icon={faCalendarDays}/></button>
        </div>
    );
}

function ArchiveDayComponent({entry, isDarkMode, idx, showArchiveEntry, generate_text_daily, focusImg, sessionToken}) {

    return (
    <>
        <div className="title-button-container">
                <h3
                    className={`title-clickable ${isDarkMode ? 'title-clickable-dark' : ''}`}
                    onClick={() => showArchiveEntry(idx)}
                >
                    <strong>{entry.date}, {entry.title ? entry.title : 'Click to generate ✨➡️'}</strong>
                </h3>
                {entry.update_available && (
                    <button
                        className='miniButton-title'
                        hover-text='Updates available for this day. Click to generate ✨'
                        onClick={() => generate_text_daily(entry.date, idx)}
                    >
                        <span><FontAwesomeIcon icon={faRotateRight} /></span>
                    </button>
                )}
        </div>
        {entry.images && 
        <div className='displayed-imgs'>
            {entry.images && entry.images.split(",").map((imgname) => {
                                                        
                                                        return (
                                                        <div key={imgname} className={isDarkMode ? 'cute-img-container cic-dark' : 'cute-img-container'}> 
                                                            <ImageComponent 
                                                                imageName={imgname} 
                                                                token={sessionToken}
                                                                maxResolution={'150px'}
                                                                handleClick={focusImg}
                                                            />
                                                        </div>);
                                                    })
            } 
        </div>
        }
        <div>{parse(entry.bullets_html)}</div>
        {/* <hr />    */}
    </>);
}

function FontSelector({onSetFont, isDarkMode}) {
    const [font, setFont] = useState(localStorage.getItem('font') || 'Montserrat');
   
    const quantizedFontSizes = ['8', '10', '12', '14', '16', '18', '20', '22', '24', '26'];
    const [fontSizeIndex, setFontSizeIndex] = useState(localStorage.getItem('fontSizeIndex') || '2');
    const [fontSize, setFontSize] = useState(`${quantizedFontSizes[fontSizeIndex]}px`)



    const handleSelectionChange = (e) => {
        console.log('font change: ',e.target.value)
        setFont(e.target.value);
    }

    const handleFontSizeSliderChange = (e) => {
        setFontSizeIndex(e.target.value);
    };


    useEffect(()=>{
        const fontSize_ = `${quantizedFontSizes[fontSizeIndex]}px`
        setFontSize(fontSize_);
        onSetFont(font, fontSize_);
        localStorage.setItem('fontSizeIndex', fontSizeIndex);
        localStorage.setItem('font', font);
    },[font, fontSizeIndex])


    return (
   <div className='font-selector-container'>
        <div className='paralel-label-input'>
            <label>Font: </label>
            <select
            className={isDarkMode ? 'input-dark' : ''}
            onChange={handleSelectionChange}
            value={font}
            >
                <option style={{fontFamily: "'Montserrat', sans-serif"}} value={'Montserrat'}>Montserrat</option>
                <option style={{fontFamily: "'Lato', sans-serif"}} value={'Lato'}>Lato</option>
                <option style={{fontFamily: "'Roboto', sans-serif"}} value={'Roboto'}>Roboto</option>
            </select>
        </div>
        <div className='paralel-label-input'>
            <label>Font Size: </label>
        </div>
            <div className='paralel-label-input'>
                <input
                className= {`font-size-slider ${isDarkMode ? 'input-dark' : ''}`} 
                type='range'
                min = '0'
                max = {quantizedFontSizes.length - 1}
                value={fontSizeIndex}
                onChange={handleFontSizeSliderChange}
                />
            <span style={{fontSize: fontSize, fontFamily: `'${font}', sans-serif`, padding: '5px', backgroundColor: `${isDarkMode ? 'rgb(48, 48, 48)' :  'rgb(221, 240, 249)'}`, color: `${isDarkMode ? 'white' : 'black'}`, borderRadius: '5px'}}>Aa</span>
            </div>
   </div>); 
}

const RemoveEntryModal = ({isDeletingEntry, setIsDeletingEntry, deleteFunction, isDarkMode, text}) => {    
    
    const onDelete = () => {
        setIsDeletingEntry(false);
        deleteFunction();
    }
    return (
        <ReactModal
        isOpen = {isDeletingEntry}
        onRequestClose = {() => setIsDeletingEntry(false)}
        contentLabel = "Deleting"
        className = {isDarkMode ? 'modal-delete-prompt dark-modal' : 'modal-delete-prompt'}
        overlayClassName = 'modal-overlay-dark'
        >
            <p>Are you sure to delete the entry?</p>
            {text && <p>{text}</p>}
            <div className='title-and-buttons'>                                               
                <button style={{color: 'green'}} onClick={onDelete}>Yes <FontAwesomeIcon icon={faTrashCan} /></button>
                <button style={{color: 'red'}} onClick={()=>setIsDeletingEntry(false)}>No <FontAwesomeIcon icon={faCircleXmark} /></button>
            </div>
        </ReactModal>
    );
}


const MessageComponent = React.forwardRef(({ message, index, sessionToken, focusImg, isDarkMode, handleEditMessage, models, linkModel, addNewModel, removeAliasFromEntry, analyzeText, generateFollowUpQuestions, idxToDeleteRef, setIsDeletingMessage, isMobile, isIOS, setDateOnMessage, removeTextHintRef, replaceByLongDash, isBetaUser, handlePaste}, messageRef) => {

    // right click or touch and hold
    const [contextMenu, setContextMenu] = useState(null);

    const [touchStart, setTouchStart] = useState(null);
    const [firstTouchInfo, setFirstTouchInfo] = useState({});

    const [isMessageEditable, setIsMessageEditable] = useState(false);  
    // contains the HTML of a message when edit is clicked. Rendered with parse(editedMessage)
    const [editedMessage, setEditedMessage] = useState('');
    // using a ref to ensure that when reading the bool on a function it is the most recent value
    const isMessageEditableRef = useRef(null);

    const editContentRef = useRef(null);
    const messagePopUpRef = useRef(null);

    // time edit
    const dateTimeHasChangedRef = useRef(false);
    const [isEditingDate, setIsEditingDate] = useState(false);


    // models
    const [isSelectionPopUp, setIsSelectionPopUp] = useState(false);
    const [isLinkingModel, setIsLinkingModel] = useState(false);
    const [isAddingNewModel, setisAddingNewModel] = useState(false);
    const [searchTextModel, setSearchTextModel] = useState('');
    const [currentAlias, setCurrentAlias] = useState('');

    const[aliasListNewModel, setAliasListNewModel] = useState([]);
    const [newModelName, setNewModelName] = useState('');
    const descriptionTextRef = useRef(null);


    // show longer message bool
    const [isViewMessageContracted, setIsViewMessageContracted] = useState(false);

    //ai on edit
    const [isAIactions, setIsAIactions] = useState(false);
    const isAIref = useRef(null);
    useEffect(()=>{isAIref.current = isAIactions}, [isAIactions])

    const contextMenuRef = useRef(null);
    const [isMouseInContext, setIsMouseInContext] = useState(false);
    const isMICboolRef = useRef(null);
    useEffect(()=>{
        isMICboolRef.current = isMouseInContext;
    },[isMouseInContext])

    useEffect(()=>{
        if (isSelectionPopUp) {
            messagePopUpRef.current.style.display = 'block';
        } else {
            messagePopUpRef.current.style.display = null;
        }
    }, [isSelectionPopUp])



    const handleGlobalClick = (e) => {
        
        setTimeout(() => {
            // Timeout waits until every change has rendered then executes the block. As the block is defined at click time -- before triggering onClick -- states are accessed via references.
            if (contextMenu && !isMICboolRef.current && (!isMobile) && !isAIref.current && messageRef.current) {
                console.log('setting context menu False because of global click')
                setIsAIactions(false);
                setContextMenu(null);
            }
        },0)

    }

    const handleTouchOutside = (e) => {
        // Not using timeOut because if using it contexMenuRef.current is always true after the first click on the message. 
        if (isMobile && contextMenuRef.current && !contextMenuRef.current.contains(e.target)) {
            setContextMenu(false);
            isAIref.current && setIsAIactions(false);
    }
    }
    
    const setDateOnMessageIdx = (date, time) =>{ 
        
        if (date !== message.date || time !== message.time){
            setDateOnMessage(date, time, index);
            dateTimeHasChangedRef.current = true;
        } else {
            dateTimeHasChangedRef.current = false;
        }

    };



    useEffect(() => {
        const isLong = message.entry.split(' ').length > 100;
        setIsViewMessageContracted(isLong);

    },[])

    useEffect(()=>{
        document.addEventListener('click', handleGlobalClick)
        document.addEventListener('touchstart', handleTouchOutside)
        
        return () => {
            document.removeEventListener('click', handleGlobalClick)
            document.removeEventListener('touchstart', handleTouchOutside)
        }
        
    },[contextMenu])


    const analyzeTextAndHideContext = (text, prompt_tag) => {
        analyzeText(text, prompt_tag);
        setIsAIactions(false);
    }

    const handleTouchStart = (e) => {
        // e.preventDefault(); 
        console.log('checking differences: ')
        setTouchStart(e.timeStamp);
        
        const touch = e.changedTouches[0];
        setFirstTouchInfo(touch);
        console.log('start positions: ')
        console.log(touch.clientX);
    }

    const handleTouchEnd = (e) => {
        // e.preventDefault();
        const touchEnd = e.timeStamp;
        console.log('width: ',  messageRef.current.offsetWidth);

        if (!isMessageEditableRef.current && e.changedTouches && e.changedTouches.length > 0) {
            const touch = e.changedTouches[0];

            if ((touchStart && touchEnd - touchStart > 300
                ) && (Math.abs(touch.clientY - firstTouchInfo.clientY )< 50)) {
                    console.log('being triggered hi!')
                    console.log('are we NOT scrolling: ', touch.clientY - firstTouchInfo.clientY < 50);
                    setContextMenu({
                    visible: true,
                    x: Math.min(touch.clientX, messageRef.current.offsetWidth - 100),
                    y: touch.clientY,
                });
            }  
            

            if (messageRef.current && !messageRef.current.contains(touch.target)) {
                setContextMenu(null);
            }

        } else {
            // if message is editable then focus on the message
            // editContentRef.current.focus();
            console.log('focusing on the message!!')
        }
        
       
    }

    const handleContextMenu = (e) => {
        console.log('handleContextMenu triggered')
        
        if (!isMessageEditableRef.current) {        
        
        e.preventDefault();
        setContextMenu({
            visible: true,
            x: Math.min(e.clientX, messageRef.current.offsetWidth - 100),
            y: e.clientY,
        });

        if (messageRef.current && !messageRef.current.contains(e.target)) {
            setContextMenu(null);
            console.log('setting context menu False')
        }}
    };



    const editEntry = () => {   
        setEditedMessage(message.entry);
        setIsMessageEditable(true);
        setContextMenu(null);    
    }



    const handleSave = () => {
        // .innerHTML is used in order to maintain \n
        const messageContent = editContentRef.current.innerHTML;
        handleEditMessage(index, messageContent, dateTimeHasChangedRef.current);
        setIsMessageEditable(false);
        setIsLinkingModel(false)
        setIsSelectionPopUp(false);
    }

    const handleCancel = () => {
        setIsMessageEditable(false);
        setIsEditingDate(false);
        setIsLinkingModel(false);
        setIsSelectionPopUp(false);
        setIsAIactions(false);
    }

    const handleTextSelect = (e) => {
        // It does not prevent the selection on IOS
        // DO I want to prevent the default settings anyway ? 
        e.preventDefault();
        const selection = window.getSelection();
        if (selection.toString().length > 0) {
            setCurrentAlias(selection.toString());            
            setIsSelectionPopUp(true);
            const range = selection.getRangeAt(0);
            const rect = range.getBoundingClientRect();
            const rectTextBox = messageRef.current.getBoundingClientRect();
            const popupRef = messagePopUpRef.current;
            popupRef.style.top = `${Math.min(rect.y, rectTextBox.y) - 40}px`;
            popupRef.style.top = `${rect.y - 60}px`;
            console.log('rect.y', rect.y)
            console.log('rectTextBox', rectTextBox.y)
            console.log('window.scrollY', window.scrollY)
            console.log('r.offsetH', popupRef.offsetHeight)
            // console.log(window.scrollY);
            // TODO: Show the pop-up in a non-intrusive way
            // popupRef.style.top = `rectTextBox.y + window.scrollY - popupRef.offsetHeight}px`;
            popupRef.style.left = `${rect.x + window.scrollX}px`;

        }
        else {
            setIsSelectionPopUp(false);
        }
    };

    const onLinkModel = () => {
        setIsLinkingModel(true);
        // load_models();
    }

    const getPersonName = (personID) => {
        let name;
        models.forEach(m => {
            if (m.id === personID) {
                name = m.name;
            }
        })
        return name;
    }

    const getInitialLettersName = (name) => (
        (name?.split(' ') || []).map((text, idx) => (
            idx === 0 ? `${text} ` : `${text[0]}.`
            )
        ).join('')
    )


    const addModelInfo = () => {
        const description = descriptionTextRef.current.textContent;
        const newModel = {name: newModelName, description: description, alias: aliasListNewModel}
        addNewModel(newModel, index);
        setisAddingNewModel(false);
        setIsLinkingModel(false);
        setIsSelectionPopUp(false);
        setAliasListNewModel([]);
        setNewModelName('');

    }
    
    const onCancelAddModel = () => {
        setisAddingNewModel(false);
        setIsLinkingModel(false);
        setIsSelectionPopUp(false);
        setAliasListNewModel([]);
        setNewModelName('');
        setCurrentAlias('');
    }
    

    const handleOnKeyDown = (e) => {
        // if key combination is Ctrl + S
       
        if (e.ctrlKey && e.key === 's') {
            e.preventDefault();
            handleSave();
            messagePopUpRef.current.style.display = null;
        }

        // if key combination is Esc
        else if (e.key === 'Escape') {
            e.preventDefault();
            handleCancel();
            messagePopUpRef.current.style.display = null;
        }
        
        replaceByLongDash(editContentRef);        
    }

    const style = {cursor: 'pointer'};

    const replaceModelInMessage = (modelItem, message) =>{
        let message_copy = message;
        Object.keys(modelItem).forEach(k => {
            message_copy = message_copy.replace(new RegExp(k, 'gi'), `<span style="background-color: #fff9c4; color: black;">${k}</span>`);
        });
        return message_copy;
    }

    const linkModelMod = ((index, idx, cAlias) => {
        linkModel(index, idx, cAlias);
        setIsLinkingModel(false);
        setIsSelectionPopUp(false);
        setCurrentAlias('');
    })

    const addAliasToList = (text) => {
        const alias = [...aliasListNewModel, text];
        setCurrentAlias('');
        const seto = new Set(alias);
        setAliasListNewModel([...seto]);
    }

    const handleNewAliasChange = (e) => setCurrentAlias(e.target.value)
    const handleNameChange = (e) => setNewModelName(e.target.value)


    const addNewModelStart = () => {
        setisAddingNewModel(true);
        setAliasListNewModel([currentAlias]);
    }

    const applyFormat = (formatType) => {
        document.execCommand(formatType);
    };

    const onRemoveEntry = (index) => {
        setContextMenu(false);
        idxToDeleteRef.current = index;
        removeTextHintRef.current = `${message.date}, ${message.time}`
        setIsDeletingMessage(true);
        // changed for two steps removal
        // removeEntry(index);
    }

    const onGenerateFollowUp = (idx) => {
        setContextMenu(null);
        generateFollowUpQuestions(idx)
    }

    const simpleHandleKeyDownDesc = (e) => {
        replaceByLongDash(descriptionTextRef);
    }

    const onContextMenuOverlay = (e) => {
        e.preventDefault();
        if (!isMobile){
            setContextMenu(null);
        }
    }

    return (
            <>

            {contextMenu && contextMenu.visible && (
                                <div className='context-menu-overlay' onContextMenu={onContextMenuOverlay} onClick={()=>setContextMenu(null)}>
                                    <div
                                        className = {isDarkMode ? "context-menu context-menu-dark" : 'context-menu'} 
                                        style={{
                                            top: contextMenu.y,
                                            left: contextMenu.x
                                        }}
                                    onMouseEnter={()=>!isMobile ? setIsMouseInContext(true): ()=>{}}
                                    onMouseLeave={()=>!isMobile ? setIsMouseInContext(true): ()=>{}}
                                    onClick={(e)=>e.stopPropagation()}
                                    ref={contextMenuRef}
                                    >
                                        {isAIactions ? 
                                            (
                                            <>  
                                                {false && <div className={`context-menu-item${isDarkMode ? '-dark' : ''}`} 
                                                onClick={()=>analyzeTextAndHideContext(message.entry, 'story')}>🧾Storify</div>}
                                                {false && <div className={`context-menu-item${isDarkMode ? '-dark' : ''}`} onClick={()=>analyzeTextAndHideContext(message.entry, 'rationality')}>Biases scan</div>}
                                                <div className={`context-menu-item${isDarkMode ? '-dark' : ''}`} onClick={()=>onGenerateFollowUp(index)}>Prompt me <FontAwesomeIcon icon={faCircleQuestion}/></div>
                                            </>
                                            ) : (
                                            <>
                                                {isBetaUser && <div className={`context-menu-item${isDarkMode ? '-dark' : ''}`} onClick={()=>setIsAIactions(true)}>Magic <FontAwesomeIcon icon={faWandMagicSparkles}/></div>}
                                                
                                                <div className={`context-menu-item${isDarkMode ? '-dark' : ''}`} onClick={editEntry}>
                                                    <span>Edit</span>
                                                    <span><FontAwesomeIcon icon={faPencilAlt}/></span>
                                                </div>
                                                
                                                <div className={`context-menu-item${isDarkMode ? '-dark' : ''}`} onClick={() => onRemoveEntry(index)}>Remove <FontAwesomeIcon icon={faTrashCan}/></div>
                                            </>
                                        )}                                                
                                    </div>
                                </div>   

            )}

            <div className={isDarkMode ? 'formatting-popup-messages fpm-dark' : 'formatting-popup-messages'} ref={messagePopUpRef}>
                <ReactModal
                isOpen={isLinkingModel}
                onRequestClose={()=>setIsLinkingModel(ps=>!ps)}
                className = {isDarkMode ? 'modal dark-modal' : 'modal'}
                overlayClassName = 'modal-overlay-dark'
                >
                <div style={{position: 'relative'}}>
                    <div className='title-and-buttons single-button dettach-button'>
                        <CloseButton onClick={()=>{setIsLinkingModel(false); setisAddingNewModel(false)}}/>
                    </div>
                </div>
                {!isAddingNewModel ? (
                    <div className='side-by-side-container' style={{flexDirection: isMobile ? 'column' : 'row'}}>
                        <div className='already-linked-models'>
                            <h4>Persons that appear in this entry</h4>
                            <p>(word) → (complete name)</p>
                            {Object.entries(message.models).map(([k,v])=>{                            
                                return (
                                    Array.isArray(v) && v.length > 0 && v.map((pid, idModel)=>(
                                    <div key={pid} className='alias-link-list'>
                                        <p>{`${k} → ${getPersonName(pid)}`}</p> 
                                        <button onClick={()=>removeAliasFromEntry(k, pid, index, idModel)} className='button-alias'><span><FontAwesomeIcon icon={faXmark} color='red'/></span></button>
                                    </div>
                                    )))
                            })}
                        </div>
                        
                        {false &&
                        // For now adding models only from the models window  
                        <div className='link-new-model'>
                            <h4>Link a new word to a model</h4>
                            <p>Search existing models: </p>
                            <input
                            className= {isDarkMode ? 'input-dark' : ''} 
                            value={searchTextModel}
                            onChange={(e)=>setSearchTextModel(e.target.value)}
                            ></input>
                            <div className='models-search'>
                                <p>{`Linking '${currentAlias}'`}</p>
                                <p style={style}onClick={addNewModelStart}><span><FontAwesomeIcon icon={faPlus}/>Add model</span></p>
                                {
                                models.map((m, idx) => {
                                    if (searchTextModel && m.name.toLowerCase().includes(searchTextModel.toLowerCase())) {
                                        return <p onClick={()=>linkModelMod(index, idx, currentAlias)} style={style}>{m.name}</p>
                                    }
                                    if (!searchTextModel) {
                                        return <p onClick={()=>linkModelMod(index, idx, currentAlias)} style={style}>{m.name}</p>
                                    }
                                })
                                }
                            </div>
                        </div>}
                    </div>
                ) : (                    
                <div className='models-add-new'>
                    <div>
                        <h4>Full name:</h4>
                            <input type='text' 
                            className= {isDarkMode ? 'input-dark' : ''} 
                            placeholder='Beff Jezos' 
                            value={newModelName} 
                            onChange={handleNameChange}/>
                    </div>
                    <div>
                        <h4>Alias:</h4>
                        <input type='text'
                        className= {isDarkMode ? 'input-dark' : ''}  
                        onChange={handleNewAliasChange}
                        placeholder='beff J'/>
                        <button onClick={()=>addAliasToList(currentAlias)}>Add alias</button>
                        <button onClick={() => setAliasListNewModel([])}><FontAwesomeIcon icon={faTrashCan}/></button>
                        <p style={{fontSize: '0.9em'}}>Alias: { aliasListNewModel.join(', ')}</p>    
                    </div>
    
        
                    <h4>Description:</h4>
                    <div
                        className={`input-description ${isDarkMode ? 'input-dark' : ''}`}
                        contentEditable='true'
                        role='textbox'
                        aria-multiline='true'
                        ref={descriptionTextRef}
                        onKeyDown={simpleHandleKeyDownDesc}
                    />    
                    <button onClick={(addModelInfo)}>Add model</button>
                </div>
                )
                }    
                </ReactModal>

                {!isLinkingModel &&
                <>
                {/* Show each model linked in the entry with a button for removal 'name: (x)' */}
                {false && Object.entries(message.models).map(([k,v])=>{
                    return (
                        <div key={v} className='alias-link-list'>
                            for instance in v
                            <p style={{fontSize: '0.75em'}}>{`${k}: ${getPersonName(v)}`}</p> 
                            <button onClick={()=>removeAliasFromEntry(k,index)} className='button-alias'><span><FontAwesomeIcon icon={faCircleXmark}/></span></button>
                        </div>
                        )
                })}
                    <div className='hover-button-container'>
                        <button style={{padding: '4px', marginLeft: '1px', fontSize: '16pt'}} onClick={()=>applyFormat('bold')}><b> B </b></button>
                        <button style={{padding: '4px', marginLeft: '1px', fontSize: '16pt'}} onClick={()=>applyFormat('italic')}><i> I </i></button>
                    </div>

                </>
                }
            </div>

            <div id={index} 
                key={index} 
                className={`message-raw ${isIOS ? 'mr-ios' : ''}`}
                ref={messageRef}
                onTouchStart={!isMessageEditable ? handleTouchStart : null}
                onTouchEnd={!isMessageEditable ? handleTouchEnd : null}
                onContextMenu={!isMessageEditable ? (isMobile ? (e)=>e.preventDefault() : handleContextMenu) : null} 
                onPaste={handlePaste}
            >
                <div className='day-time-edit-container'>
                    <div className='date-and-names-row'>                     
                    {isMessageEditable ?
                    (
                        isEditingDate ?  (
                            <DatePickerMessage 
                            init_date={message.date} 
                            init_time={message.time} 
                            setDateOnMessage={setDateOnMessageIdx}
                            setIsEditingDate={setIsEditingDate}
                            isDarkMode={isDarkMode}/>
                        ) : ( 
                        <span style={{fontSize: '0.85em', color: 'gray', marginBottom: '0px'}}>{message.date}, {message.time} 
                            <button style={{fontSize: '8pt', padding: '2px'}} onClick={()=> setIsEditingDate(true)}
                            ><FontAwesomeIcon icon={faPencilAlt}/>
                            </button>
                        </span>
                        )
                    
                    ) : (
                    <>  
                        <span style={{fontSize: '0.85em', color: 'gray', marginBottom: '0px'}}>{message.date}, {message.time}</span>
                    </>
                    )
                    }
                    {!isEditingDate && 
                    <span style={{fontSize: '0.85em', color: 'gray', marginBottom: '0px'}}>
                        {Object.values(message.models)
                            .flatMap(idList=>idList)
                            .map( id=> getInitialLettersName(getPersonName(id)))
                            .join(' | ') }
                        {isMessageEditable && <button onClick={onLinkModel} style={{fontSize: '8pt', padding: '2px'}}><FontAwesomeIcon icon={faPersonCirclePlus}/></button>}
                    </span>}
                    </div>
                </div>
    
                <div className = 'message-text-container'
                        style={(isMobile && !isMessageEditable) ? {userSelect: 'none', WebkitUserSelect: 'none'} : {userSelect: 'text'}}
                >
                    {isMessageEditable ? (
                        <div 
                            ref={editContentRef} 
                            contentEditable={true} 
                            role='text-box' 
                            aria-multiline={true} 
                            onKeyDown={handleOnKeyDown} 
                            onSelect={handleTextSelect}
                        > 
                        {parse(editedMessage)}
                        </div>
                        // Alternatively it can be set with .innerHTML in useEffect([isMessageEditable])
                        ) : (
                            <>
                            {isViewMessageContracted && parse((message.entry).split(' ').slice(0,100).join(' '))}
                            {!isViewMessageContracted && parse(message.entry)}
                            {message.entry.split(' ').length > 100 && !isViewMessageContracted && 
                            <div className='show-more-icon'>
                                <button className={`show-less-more-buttons ${isDarkMode ? 'tp-dark' : ''}`} onClick={()=>{setIsViewMessageContracted(ps => !ps)}}><FontAwesomeIcon icon={faAngleDoubleUp}/></button>
                            </div>
                            }
                            {isViewMessageContracted && 
                            <div className='show-more-icon'>
                                <button className={`show-less-more-buttons ${isDarkMode ? 'tp-dark' : ''}`} onClick={()=>{setIsViewMessageContracted(ps => !ps)}}><FontAwesomeIcon icon={faAngleDoubleDown}/></button>
                            </div>}
                            </>
                        )
                    }
                </div>


                {isMessageEditable && 
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <button className={`button-edit-entry ${isDarkMode ? 'bee-dark' : ''}`} onClick={handleSave}><span><FontAwesomeIcon icon={faCircleCheck} style={{color: 'green'}}/></span></button>
                    <button className={`button-edit-entry ${isDarkMode ? 'bee-dark' : ''}`} onClick={handleCancel}><span><FontAwesomeIcon icon={faCircleXmark} style={{color: 'red'}}/></span></button>
                </div>
                }

                {message.images.length > 0 && message.images.map((img, imgIndex) => {
                                        if (img.startsWith('data:image')){
                                            return(
                                            img && <img key={imgIndex} src={img} alt="Attached" onClick={()=>focusImg(img)} className='img-displayed-in-message'/>
                                            );
                                        } else { 
                                            return (img &&                                                  
                                                <ImageComponent 
                                                    imageName={img} 
                                                    token={sessionToken}
                                                    key={imgIndex}
                                                    handleClick={focusImg}
                                                />
                                                )
                                        }
                                        })
                }
    
                {
                message.audio_file && <AudioEmbed audioName={message.audio_file} token={sessionToken}/>  
                }

                
            </div>
        </>
    );
});



function ChatEntries ({user, user_token, socket_ref, isDarkMode, setIsGeneratingAnswerLLM, encriptionKey}) {

    const [history, setHistory] = useState(() => {
        const stored = sessionStorage.getItem('history')
        try {
            return stored ? JSON.parse(stored) : [];
        } catch (e) {
            console.error("Error parsing 'history' from session storage", e);
            return [];
        }
    });

    const [userMessage, setUserMessage] = useState('');
    const refHistory = useRef(history);

    // disambiguate names
    const [isDisambModalOpen, setDisambModalOpen] = useState({'test': true});
    const [disambNames, setDisambNames] = useState({});
    const [dropdownSelectedNames, setDropdownSelectedNames] = useState({});

    
    // stream answer chat
    const [currentAssistantAnswer, setCurrentAssistantAnswer] = useState("");
    const refCurrentAnswer = useRef(currentAssistantAnswer);
    const [currentQuestion, setCurrentQuestion] = useState("");
    const [isAnswerFinished, setIsAnswerFinished] = useState(true);

    // calendar pop-up
    const [isShowFilterModal, setIsShowFilterModal] = useState(false);
    const [selectedDate, setSelectedDate] = useState({min: null, max: null});
    const [stringFilterDate, setStringFilterDate] = useState("-, -");


    const [showPromptQA, setShowPromptQA] = useState(false)
    const [displayedQAPrompt, setDisplayedQAPrompt] = useState('')
    const [displayedQARefs, setDisplayedQARefs] = useState({})

    useEffect(()=>{
        refCurrentAnswer.current = currentAssistantAnswer
    }, [currentAssistantAnswer])

    useEffect(()=>{
        refHistory.current = history
        sessionStorage.setItem('history', JSON.stringify(history));
    }, [history])


    
    const showPromptandReferences = (prompt, refDocsList) => {
        setShowPromptQA(true);
        setDisplayedQAPrompt(prompt);
        setDisplayedQARefs(refDocsList);
    }
    
    function findTextInBrackets(text) {
        const pattern = /<([^>]*)>/g;
        const matches = text.match(pattern);
    
        console.log(matches)
        if (matches) {
            // Extracting the content inside the brackets
            return matches.map(match => match.slice(1, -1));
        } else {
            // Return an empty array if no matches are found
            return [];
        }
    }

    function getAnswerLLMAnswerNOStream (data) {
        const answer = data.response
        const newHistoryObj = {who: 'Bot', content: answer.answer, prompt: answer.prompt, source_docs: answer.source_docs};
        const newHistory = [...refHistory.current, newHistoryObj]
        setHistory(newHistory);
        setIsGeneratingAnswerLLM(false);
    }

    function handleAppendStreamResponse(data) {
        if (data.first) {
            setCurrentAssistantAnswer("");
            setIsAnswerFinished(false);
            const filter_info = data.metadata.filter_info;
            if (filter_info !== stringFilterDate) {
                setStringFilterDate(filter_info);
            }
        }
        
        if (data.last) {
            setIsAnswerFinished(true);
            const splitted =  data.full_answer.split('Answer: ')
            const references_text = splitted[0].split('References: ')[1]
            console.log(data.full_answer)

            let references;
            try {
                references = findTextInBrackets(references_text)[0];
            } catch {
                references = '';
            }

            const newResponse = {who: 'Bot', content: splitted[1] ? splitted[1] : data.full_answer, prompt: data.model_prompt, refs: references, refsObj: data.metadata.refs_obj, complete_answer: data.full_answer}
            const newHistory = [...refHistory.current, newResponse]
            setHistory(newHistory);

        }

        if (!data.full_answer.includes('Answer: ')) {
            setCurrentAssistantAnswer('generating')
        } else {
            const splitted =  data.full_answer.split('Answer: ')
            setCurrentAssistantAnswer(splitted[1] ? splitted[1] : data.full_answer)
            
        }
    }

    function handleDisambNames(data) {
        const names = data.names
        const isModalOpenDict = {}
        const okNames = {}
        const notOkNames = {}
        Object.entries(names).map(([k, v]) => {
            
            if ((v.length) > 2){
                isModalOpenDict[k] = true;
                notOkNames[k] = v
            } else {
                okNames[k] = v[v.length - 1];
            }  
        });
        setDisambNames(notOkNames);
        setDisambModalOpen(isModalOpenDict);
        setDropdownSelectedNames(okNames);
    }

    useEffect(()=>{

        if (!socket_ref.current) {
            // right now we are using  HTTP Long-Polling because in the server size I set 'max_http_buffer_size = 500MB' and this allows to send big images to the server. Otherwise sockets are not received (but sent in the frontend)
            true ? (
                socket_ref.current = io(root_server_url, 
                    {
                        transports: ["polling"], // Start with HTTP Long-Polling
                        extraHeaders: {
                          "ngrok-skip-browser-warning": "true" // Intended to bypass the interstitial page
                        }
                      })
            ) : socket_ref.current = io(root_server_url);

        }        

        socket_ref.current.on('append_msg_to_answer', handleAppendStreamResponse)
        socket_ref.current.on('answer_llm', getAnswerLLMAnswerNOStream)
        socket_ref.current.on('disambiguate_names', handleDisambNames)
    
        return () => {
            if (socket_ref.current) {
                socket_ref.current.off('append_msg_to_answer', handleAppendStreamResponse)
                socket_ref.current.off('disambiguate_names', handleDisambNames)
                socket_ref.current.off('answer_llm', getAnswerLLMAnswerNOStream)
            }
        }

    }, []) 




    useEffect(() => {
        // check if all modals are closed and if so proceed with answering the question
        if (Object.values(isDisambModalOpen).every(v => v === false)) {
            socket_ref.current.emit('ask_llm', {query: currentQuestion,
                                                history: history, 
                                                user_token: user_token,
                                                encription_key: encriptionKey,
                                                selections: dropdownSelectedNames,
                                                dates_filter: stringFilterDate}
                );

            setDropdownSelectedNames({});
            setDisambNames({});
            setCurrentQuestion("");
            
            }
    }, [isDisambModalOpen])


    const handleSendMessage = () => {
        
        const appended_message = userMessage;
        const newHistory = [...history, {who: user.split(" ")[0], content: appended_message}];
        setHistory(newHistory);
        setCurrentQuestion(userMessage);
        setUserMessage('');
        setIsGeneratingAnswerLLM(true);
        socket_ref.current.emit('pre_ask', {query: appended_message, 
                                            history: newHistory,
                                            user_token: user_token,
                                            encription_key: encriptionKey,
                                            dates_filter: stringFilterDate
                                            });
    }

    function onSelectName (key, selected_name) {
        const newSelection = {...dropdownSelectedNames};
        newSelection[key] = selected_name;
        setDropdownSelectedNames(newSelection);
        
        const  modalOpens = {...isDisambModalOpen};
        modalOpens[key] = false;
        setDisambModalOpen(modalOpens);
    }

    function formatDateObjToString(date) {

        if (!date) return '-'

        let day = date.getDate();
        let month = date.getMonth() + 1; // getMonth() returns a zero-based index
        let year = date.getFullYear();
      
        // Adding leading zeros for day and month if they are less than 10
        day = day < 10 ? '0' + day : day;
        month = month < 10 ? '0' + month : month;
      
        return `${month}-${day}-${year}`;
      }

    function onSelectDate(which, date_obj) {
        const selection = {...selectedDate}
        selection[which] = date_obj
        setSelectedDate(selection)

    }

    function onApplyFilter(){
        setStringFilterDate(`${formatDateObjToString(selectedDate['min'])}, ${formatDateObjToString(selectedDate['max'])}`);
        setIsShowFilterModal(false);
    }

    function removeFilter() {
        setStringFilterDate("-, -")
        setSelectedDate({min: null, max: null})
    }

    const handleKeyDown = (e)=>{
        
        if (e.key === 'Enter') {
            handleSendMessage();
        }
    }
    


    return (
        <div className={`chat-container ${isDarkMode ? 'cc-dark' : ''}`}>
            <div className={`chat-header ${isDarkMode ? 'ch-dark' : ''}`}>
                {stringFilterDate !== "-, -" && <>Dates between: ({stringFilterDate}){" "}</>}
                <button 
                    onClick={() => {setHistory([])}}
                >New chat</button>
                <button onClick = {() => setIsShowFilterModal(true)}><FontAwesomeIcon icon={faFilter}/></button>
                <ReactModal
                isOpen = {isShowFilterModal}
                onRequestClose = {() => setIsShowFilterModal(false)}
                className = {isDarkMode ? 'modal dark-modal' : 'modal'}
                overlayClassName = 'modal-overlay-dark'
                >
                    <h2>Dates filter</h2>
                    <div> From: 
                        <DatePicker
                            selected = {selectedDate['min']}
                            onChange = {(date) => onSelectDate('min', date)}
                        >
                            * entries with a date smaller than the selected date will be excluded from the search set. Set blank to allow all.
                        </DatePicker>                    
                    </div>
                    <div> To: 
                        <DatePicker
                            selected = {selectedDate['max']}
                            onChange = {(date) => onSelectDate('max', date)}
                        >
                            * entries with a date greater than the selected date will be excluded from the search set. Set blank to allow all.
                        </DatePicker>
                    </div>
                    {/* Add a checkbox to negate the the filter */}
                    <button onClick={onApplyFilter}>Apply</button>
                    <button onClick = {removeFilter}><FontAwesomeIcon icon={faTrashCan}/></button>
                    <button onClick={() => setIsShowFilterModal(false)}>Cancel</button>
                </ReactModal>

            </div>
            <div className='chat-messages'>
                <ReactModal 
                isOpen={showPromptQA} 
                onRequestClose={()=>setShowPromptQA(false)}
                className = {isDarkMode ? 'modal dark-modal' : 'modal'}
                overlayClassName = 'modal-overlay-dark'
                >
                    <div className='title-and-buttons'>
                        <h4>references</h4> 
                        <CloseButton onClick={()=>setShowPromptQA(false)}/>
                    </div>
                    
                    {displayedQARefs.length > 0 && displayedQARefs.map((v)=>{
                        return <p>{v}</p>
                    })
                    }
                    {false && 
                    <div>
                        <h4>prompt:</h4> 
                        {displayedQAPrompt.split('\n').map((line) => {
                            return <p>{line}</p>
                        })}
                    </div>

                    }

                </ReactModal>
                {Array.isArray(history) && 
                history.map((msg)=>{
                    return (
                    <div style={{position: 'relative'}}>
                        <div className='row-display'>
                            <p><strong>{msg.who}:</strong> {msg.content}</p>
                        </div>
                        {msg.who === "Bot" && 
                        <div className='show-more-icon'>
                            <button className={`show-less-more-buttons qa-button ${isDarkMode ? 'tp-dark' : ''}`} onClick={()=>showPromptandReferences(msg.prompt, msg.source_docs)}>   
                            <span><FontAwesomeIcon icon={faInfo} /> </span>
                        </button>
                        </div>
                        }
                    </div>
                    );
                })
                }

            </div>
            <div className={`chat-input ${isDarkMode ? 'ci-dark' : ''}`}>
                <input
                    type='text'
                    value={userMessage}
                    onChange={(e)=>setUserMessage(e.target.value)}
                    placeholder='type a message ...'
                    onKeyDown={handleKeyDown}
                />
                <button onClick={handleSendMessage}><span><FontAwesomeIcon icon={faPaperPlane}/></span></button>
            </div>

            <div>
                {Object.entries(disambNames).map(([k,v])=>{
                    console.log(isDisambModalOpen[k]);
                    return (<ReactModal
                            isOpen = {isDisambModalOpen[k]}
                            className = {isDarkMode ? 'modal dark-modal' : 'modal'}
                            overlayClassName = 'modal-overlay-dark'
                            >
                                <div key={k}>
                                    <p>Which {k} are you talking about?</p>
                                    <select onChange={e => {onSelectName(k, e.target.value)}}>
                                        {v.map(v=> <option key={k} value={v}>{v}</option>)}
                                    </select>
                                </div>

                            </ReactModal>);
                })}
            </div>
        </div>

    );
}

function CloseButton({onClick}) {

    return (
        <button className='dettach-button close-button' onClick={onClick}><span><FontAwesomeIcon icon={faXmark}/></span></button>
    );
}


const QuestionsSlider = ({ followUpsQuestions, setFollowUpQuestions, isMobile,  onRemoveFollowUpQuestion, onAnswerQuestion, isDarkMode, markQuestionAsSeen}) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isShowInfo, setIsShowInfo] = useState(false);
    const firstTouchInfo = useRef(null);


    useEffect(() => {
        
        if (followUpsQuestions[currentIndex] && followUpsQuestions[currentIndex].isNew) {
            setTimeout(()=>{
                followUpsQuestions[currentIndex].isNew = false;
                setFollowUpQuestions([...followUpsQuestions]);
                markQuestionAsSeen(followUpsQuestions[currentIndex].source_id);
            }, 5000);
        }

    }, [currentIndex])

    const onRemoveButtonClicked = () => {
        onRemoveFollowUpQuestion(currentIndex);
        if (currentIndex == followUpsQuestions.length - 1) {
            setCurrentIndex(currentIndex - 1)
        };
    }
  
    const goToNext = () => {
      if (currentIndex < followUpsQuestions.length - 1) {
        setCurrentIndex(currentIndex + 1);
      } else if (currentIndex === followUpsQuestions.length - 1) {
        setCurrentIndex(0);
      }
    };
  
    const goToPrev = () => {
      if (currentIndex > 0) {
        setCurrentIndex(currentIndex - 1);
      } else if (currentIndex === 0) {
        setCurrentIndex(followUpsQuestions.length - 1);
      }
    };
  
    
    const onTouchStart = (e) => {
        firstTouchInfo.current = e.changedTouches[0]; 
    }

    const onTouchEnd = (e) => {
        const touchEndObj = e.changedTouches[0];    
        
        if (firstTouchInfo.current) {
            if (touchEndObj.clientX - firstTouchInfo.current.clientX > 75) {
                goToPrev();
            } else if (touchEndObj.clientX - firstTouchInfo.current.clientX < -75) {
                goToNext()
            } 
        }
    }


    const replaceEvidenceInMessage = (evidence, text) => {
        return text.replace(evidence.trim(), `<span style="background-color: #fff9c4; color: black;">${evidence.trim()}</span>`);
    }


    return (
        <div className='followUpQuestions-main-container'
        onTouchStart={onTouchStart}
        onTouchEnd={onTouchEnd}
        >
            {
                isShowInfo && (
                    <div className='info-container'>
                        <div className='title-and-buttons single-button dettach-button'>
                            <CloseButton onClick={()=>setIsShowInfo(false)}/>
                        </div>
                        <div className='info-text'>
                            <h4>Question</h4>
                            <p>{followUpsQuestions[currentIndex].question}</p>
                            <h4>Why it matters?</h4>
                            <p>{followUpsQuestions[currentIndex].reasoning}</p>
                            <h4>Source entry</h4>
                            <p style={{fontSize: '0.9em', color: 'gray'}}>(Evidence for the question highlighted)</p>
                            <p>{parse(replaceEvidenceInMessage(followUpsQuestions[currentIndex].evidence, followUpsQuestions[currentIndex].source_text))}</p>
                        </div>
                    </div>
                )
            }
            {!isShowInfo && followUpsQuestions.length > 0 && (
                <div className='fu-container' key={currentIndex}>
                    {!isMobile && (
                        <button onClick={goToPrev} className="navigate-btn">
                        <FontAwesomeIcon icon={faArrowLeft} />
                        </button>
                    )}
                    <div className={`question-and-buttons-cotainer ${isDarkMode ? 'qabc-dark' : ''}`}>
                            <div className='question-container'>
                                {followUpsQuestions[currentIndex].question}
                            </div>
                            <div className='buttons-row'>
                                <button onClick={() => onAnswerQuestion(followUpsQuestions[currentIndex].question)}>
                                <FontAwesomeIcon icon={faFeather}/>
                                </button>
                                <button onClick={()=>setIsShowInfo(ps=>!ps)}>
                                <FontAwesomeIcon icon={faInfo}/>
                                </button>
                                <button onClick={() => {}}>
                                <FontAwesomeIcon onClick={onRemoveButtonClicked} icon={faTrashCan}/>
                                </button>
                            </div>
                    
                    <div className='questions-counter'>
                        {followUpsQuestions[currentIndex].isNew && <span style={{color: 'red', fontSize: '0.9em'}}>New!🔥</span>}
                        {`${currentIndex + 1}/${followUpsQuestions.length}`}
                    </div>                    
                    </div>
                    {!isMobile && (
                        <button onClick={goToNext} className="navigate-btn">
                        <FontAwesomeIcon icon={faArrowRight} />
                        </button>
                    )}

                </div>
            )}
            {!isShowInfo && followUpsQuestions.length === 0 && 
            <div className={`question-and-buttons-cotainer ${isDarkMode ? 'qabc-dark' : ''}`}>
                Generate questions and they will appear here!
            </div>
            }
        </div>
    );
  };


function ChatApp() {

    //is mobile
    const [isMobile, setIsMobile] = useState(false);
    const [isIOS, setIsIOS] = useState(false);

    useEffect(()=> {
        const checkMobile = () => {
            // Conditions to determine if the device is mobile
            return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
              || (navigator.maxTouchPoints && navigator.maxTouchPoints > 2 && /MacIntel/.test(navigator.platform));
          };
          const checkIOS = () => {
            // Check for iOS device user agents
            const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) || 
              (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1); // This checks for iPads running iPadOS 13 or later
            return isIOS;
          };
        setIsIOS(checkIOS());
        setIsMobile(checkMobile());

    }, []);

    const [activeTab, setActiveTab] = useState(sessionStorage.getItem('activeTab') || 'today');
    const [isSearchingToday, setIsSearchingToday] = useState(false);
    
    // search models
    const [isSearchingModels, setIsSearchingModels] = useState(false);
    const [isSearchModelTerm, setIsSearchModelTerm] = useState('');
    const searchBarModelRef = useRef(null);

    const [aActiveTab, setAActiveTab] = useState('daily')
    
    // modal to prompt the user to delete an entry in Archive
    const [isDeletingEntry, setIsDeletingEntry] = useState(false);

    // modal to prompt the user to delete an entry in messages
    const idxToDeleteRef = useRef(-1);
    const removeTextHintRef = useRef('');
    const [isDeletingMessage, setIsDeletingMessage] = useState(false);

    // config options
    const [isDarkMode, setIsDarkMode] = useState(localStorage.getItem('isDarkMode') === 'true' ? true : false);
    
    useEffect(()=>{
        localStorage.setItem('isDarkMode', isDarkMode);
    },[isDarkMode])

    const [encriptionKey, setEncriptionKey] = useState(localStorage.getItem('encriptionKey') || '');
    const [tentaiveKey, setTentaiveKey] = useState('');
    const keyHolderRef = useRef(null);



    const emptyContainers = (dropTabs) => {
        
        if (!dropTabs) {
            dropTabs = {'today': true, 'archive': true, 'models': true, 'question': true, 'userInfo': true};
        }

        if (dropTabs['today']) {
            setMessages([]);
        }

        if (dropTabs['archive']) {
            setArchEntries([]);
            setArchWEntries([]);
            setArchMEntries([]);
        }

        if (dropTabs['models']) {
            setModelsList([]);
        }

        if (dropTabs['question']) {
            setFollowUpQuestions([]);
        }

        if (dropTabs['userInfo']) {
            setUsersInfo({});
        }
    }

    const get_streak = async () => {
        const response = await fetch(`${fetch_url}/get_streak`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({user_token: sessionToken})
            })

            const data = await response.json();
            setStreak(data.streak);

    };

    const reload_content = async (activeTab) => {
        const light_version = true;
        const dropTabs = {'today': true, 'archive': true, 'models': true, 'question': true, 'userInfo': true};
        if (activeTab === 'today') {
            if (light_version || messages.length === 0) {
                await load_messages();
                dropTabs['today'] = false;
            
            } else {
                dropTabs['today'] = false;
            }
            if (light_version || modelsList.length === 0) {
                await load_models();
                dropTabs['models'] = false;
            } else {
                dropTabs['models'] = false;
            }
        } 
        
        if (activeTab === 'models') {
            if (light_version) {
                await load_models();
                dropTabs['models'] = false;
            }
        }

        if (activeTab === 'archive') {
            // update the state of archive on server side

            if (light_version || archEntries.length === 0) {
                checkForUpdates();
                await load_archive();
                await load_archive_month();
                await load_archive_week();
                dropTabs['archive'] = false;
            } else {
                dropTabs['archive'] = false;
            }
        }

        if (activeTab === 'question') {
            if (light_version || followUpQuestions.length === 0) {
                await load_followUps();
                dropTabs['question'] = false;
            } else {
                dropTabs['question'] = false;
            }
        }

        if (activeTab === 'stats') {
            await load_users_info();
            dropTabs['userInfo'] = false;
        } 

        if (activeTab === 'home') {
            get_streak();
        }
    
    return dropTabs;
    
    }

    useEffect(()=>{
        localStorage.setItem('encriptionKey', encriptionKey);
        
        if (sessionToken && encriptionKey){
                setTimeout(()=>setActiveTab('home'), 1000);
                
            } else {
                emptyContainers();
            }

    },[encriptionKey])

    // raw entries input bar
    const [messages, setMessages] = useState([]);
    const messagesListRef = useRef([]);
    const [dummyBatchLoader, setDummyBatchLoaderScrollUp] = useState(['dummy']);

    const [lenMessages, setLenMessages] = useState(0);
    // const [currentMessage, setCurrentMessage] = useState('');
    const [attachedImgs, setAttachedImgs] = useState([]);
    
    // follow-up questions
    const [followUpQuestions, setFollowUpQuestions] = useState([]);
    const [questionBeingAnswered, setQuestionBeingAnswered] = useState('');
    const [displayedInfoFollowUpMessage, setDisplayedInfoFollowUpMessage] = useState(''); 

    // user info
    const [usersInfo, setUsersInfo] = useState({});
    
    // input bar ref
    const textEntryRef = useRef(null);
    // images preview ref 
    const imgsPreviewContainerRef = useRef(null);

    //Ref for the upload file input
    const hiddenUploadFileAInput = useRef(null);

    
    // index for loading a subset of the raw entries
    const [isScreenAtBottom, setIsScreenAtBottom] = useState(true);
    const [maxVisibleTBatchID, setMaxVisibleTBatchID] = useState(0);

    const [oldScrollHeight, setOldScrollHeight] = useState(0);
    const [scrollingUp, setScrollingUp] = useState(true);
    const [editingDate, setEditingDate] = useState('');
    
    const messagesEndRef = useRef(null);
    const socket_ref = useRef(null);
    const modalImgHolderTodayRef = useRef(null);

    // user info
    const [sessionToken, setSessionToken] = useState(localStorage.getItem('sessionToken') || '');
    const [username, setUsername] = useState(localStorage.getItem('username') || '');
    const [userEmail, setUserEmail] = useState(localStorage.getItem('userEmail') || '');
    const [isBetaUser, setIsBetaUser] = useState(localStorage.getItem('isBetaUser') === 'true' ? true : false);
    const [streak, setStreak] = useState(localStorage.getItem('streak') || 0);


    // daily
    const [archEntries, setArchEntries] = useState([]);
    const archEntriesRef = useRef(archEntries);
    useEffect(()=>{archEntriesRef.current = archEntries}, [archEntries])
    const archDivRefs = useRef([]);

    // weekly
    const [archWEntries, setArchWEntries] = useState([]);
    const archWEntriesRef = useRef(archWEntries);
    useEffect(()=>{archWEntriesRef.current = archWEntries}, [archWEntries])


    // monthly 
    const [archMEntries, setArchMEntries] = useState([]);
    const archMEntriesRef = useRef(archEntries);
    useEffect(()=>{archMEntriesRef.current = archMEntries}, [archMEntries])

    // models
    const [modelsList, setModelsList] = useState([]);
    const modelListRef = useRef(modelsList); 
    useEffect(()=>{modelListRef.current = modelsList}, [modelsList]);
    const [isAddingNewModel, setIsAddingNewModel] = useState(false);
    const [newModelName, setNewModelName] = useState('');
    const [newModelAliasList, setNewModelAliasList] = useState([]);
    const [currentAlias, setCurrentAlias] = useState('');
    const newModelDescriptionRef = useRef(null);
    
    const [displayedAID, setDisplayedAID] = useState(sessionStorage.getItem("displayedAID") || null);
    const [isModalAOpen, setIsModalAOpen] = useState(() => {
        const saved = sessionStorage.getItem('isModalAOpen');
        console.log('it was saved as: ', saved);
        return saved === "true";
    });
    const fontSizeRef = useRef(null);
    
    // ref to app-content classname div
    const appContentDivRef = useRef(null);

    // Check for updates and generate summaries, bullet points
    const [isModalCheckUpdatesOpen, setIsModalCheckUpdatesOpen] = useState(false);
    const [categoryNeedsUpdate, setCategoryNeedsUpdate] = useState({});


    // current entry in focus for a given date 
    const [editingArchEntry, setEditingArchEntry] = useState(() => {
        const stored = sessionStorage.getItem('editingArchEntry')
        try {
            return stored ? JSON.parse(stored) : {};
            console.log(JSON.parse(stored));
        } catch (e) {
            console.error("Error parsing editingArchEntry from session storage", e);
            return {};
        }
    });
    
    //archive photos
    const [attachedAImgs, setCurrentAImage] = useState([]);
    const [numDisplayedImgs, setNumDisplayedImgs] = useState(0);

    // stream answer archive generations
    const [unfinishedAGeneration, setUnfinishedAGeneration] = useState("");
    const [generatedAContent, setGeneratedAContent] = useState([]);
    const genAContentRef = useRef(null);
    useEffect(()=>{genAContentRef.current = generatedAContent}, [generatedAContent])


    const chatMessagesRef = useRef(null);
    const messagesRefComponentsList = useRef([]);


    //analyize entry e.g. biases
    const [isAnalyzingEntry, setIsAnalyzingEntry] = useState(null);
    const [analyzedData, setAnalyzedData] = useState('');


    // offset Pixels to calculate the messages height: 
    const offsetPixelsH = 80;

    useEffect(()=>{
        
        if (messagesListRef.current) {
            // If a message is added scroll into view (after clicking paperPlane)
            const delta = messages.length - messagesListRef.current.length;
            delta === 1 && messagesEndRef.current.scrollIntoView({behavior: 'auto', block: 'start'});

            // Load the messages after reloadContent -- if there was no messages in the list 
            true && messagesListRef.current.length == 0 &&  messagesEndRef.current && setTimeout(()=>messagesEndRef.current.scrollIntoView({behavior: 'auto', block: 'start'}), 0);
        }
        messagesListRef.current = messages
        
        messagesRefComponentsList.current = messages.map((_,i) => { 
            return messagesRefComponentsList.current[i] || createRef();

        })
    
    },[messages])

    const setMessagesHeight = () => {
        setTimeout(() => {            
            if (textEntryRef.current && chatMessagesRef.current) {
                const imgPrevHeight = imgsPreviewContainerRef.current.offsetHeight;
                const inputHeight = textEntryRef.current.offsetHeight;
                const newH = `calc(100vh - ${offsetPixelsH}px - ${inputHeight}px - ${imgPrevHeight}px)`
                chatMessagesRef.current.style.height = newH;
            }

        },0);
    }

    useEffect(()=>{
        // Apply the font size to the first render (after refresh)
        const fontSize = localStorage.getItem('fontSize');
        const font = localStorage.getItem('font');

        if (font && fontSize) {
            onFontChange(font, fontSize);
        }

        // Adjust messages height once it has rendered
        setMessagesHeight();

    }, [])




    // stream answers for on demand single date generations
    const [isModalGenerationAOpen, setIsModalGenerationAOpen] = useState(false);
    const [infoGenerationA, setInfoGenerationA] = useState({});

    const [isModalPicturesOpen, setIsModalPicturesOpen] = useState(false);
    const [imgSrcModal, setImgSrcModal] = useState('');

    // search results
    const [resultsList, setResultsList] = useState([]);
    const [searchBarQuery, setSearchBarQuery] = useState('');
    const [isGoneExploring, setIsGoneExploring] = useState(false);
    const isGoneExploringRef = useRef(false);
    const [IsLoadingEmbeddingSearch, setIsLoadingEmbeddingSearch] = useState(false);

    // posible race condition in which they read the same ps, but states recomended over values because they update the ui
    // Loading indicators
    const [isLoadingEntries, setIsLoadingEntries] = useState({archDay: false, archWeek: false, archMonth: false, messages: false, models: false, followUp: false, userInfo: false});
    const [isTranscribingAudio, setIsTranscribingAudio] = useState(false);
    const [isGeneratingQuestions, setIsGeneratingQuestions] = useState(false);
    const [isGeneratingAnswerLLM, setIsGeneratingAnswerLLM] = useState(false);

    //advanced search messages
    const [isModalAdvancedSearchVisible_msg, setIsModalAdvancedSearchVisible_msg] = useState(false);
    const availableDatesListRefMsg = useRef([]);

    // List of available days
    const availableDatesListRefArch = useRef([]);

    



    useEffect(() => {isGoneExploringRef.current = isGoneExploring}, [isGoneExploring])

    const [idxsDisplayedMinMax, setIdxsDisplayedMinMax] = useState({});
    
    const [isRockBottomTop, setIsRockBottomTop] = useState({});
    const rockBottomRef = useRef(null);
    useEffect(()=>{rockBottomRef.current = isRockBottomTop},[isRockBottomTop])

    const idxsObjectRef = useRef(null);
    useEffect(()=>{idxsObjectRef.current = idxsDisplayedMinMax}, [idxsDisplayedMinMax])

    const [filterSearchSelectedTimeDelta, setFilterSearchSelectedTimeDelta] = useState('all_time'); 




    const focusImg = (imgSrc) => {
        console.log('focusing img')
        console.log(imgSrc)
        setIsModalPicturesOpen(true);
        setImgSrcModal(imgSrc);
    }

    
    const fetch_dates_msg = async () => {
        const response = await fetch(`${fetch_url}/fetch_available_dates_on_messages`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({user_token: sessionToken})
            });

        const data = await response.json();
        availableDatesListRefMsg.current = data.dates;
    }

    const fetch_dates_arch = async () => {
        const response = await fetch(`${fetch_url}/fetch_available_dates_on_archive_day`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({user_token: sessionToken})
            });

        const data = await response.json();
        availableDatesListRefArch.current = data.dates;
        
    }




    function decryptMessage(key, iv, encryptedData) {
        
        const keyWords = CryptoJS.enc.Hex.parse(key);
        const ivWords = CryptoJS.enc.Hex.parse(iv);
    
        let decrypted;
        try{
             decrypted = CryptoJS.AES.decrypt(
                {
                    ciphertext: CryptoJS.enc.Hex.parse(encryptedData)
                }, 
                keyWords, 
                { 
                    iv: ivWords,
                    mode: CryptoJS.mode.CBC,
                    padding: CryptoJS.pad.Pkcs7
                }
            );
        
        return decrypted.toString(CryptoJS.enc.Utf8);
        } catch (e) {
            console.error('Decryption error', e);
            return encryptedData;
        }

    
        
    }

    const onFontChange = (font, fontSize) => {
        //might be better to set the fontsize with a state, so that it triggers a rerender
        fontSizeRef.current = {fontSize: fontSize, fontFamily: `'${font}', sans-serif`};
    }


    const load_users_info = async () => {
        setIsLoadingEntries(ps => ({...ps, userInfo: true}));
        const response = await fetch(`${fetch_url}/get_user_stats`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({user_token: sessionToken})
    });

    const data = await response.json();
    setUsersInfo(data.info);
    console.log('data: ', data.info);
    setIsLoadingEntries(ps => ({...ps, userInfo: false}));

    }

    const load_messages = async () => {
        setIsLoadingEntries(ps => ({...ps, messages: true}));
        try {
            const response = await fetch(`${fetch_url}/load_entries_batch`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({user_token: sessionToken, 
                                max_idx: maxVisibleTBatchID, 
                                len_messages: messages.length, 
                                batch_size: 40})
        });

            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            const data = await response.json();
            
            const decrypted_mgs = data.messages.map((item) => {
                    
                    const redeable_entry = decryptMessage(encriptionKey, ...item.entry.split('_'));
                    const readable_models = decryptMessage(encriptionKey, ...item.models.split('_'));

                    let models;
                    try {
                        models = eval(`(${readable_models})`)
                    } catch (e) {
                        models = readable_models;
                    }

                    return {...item, entry: redeable_entry, models: models};
            });

            setMessages([...decrypted_mgs, ...messages]);
            setIsLoadingEntries(ps => ({...ps, messages: false}));

            // set max Batch id if it wasn0t previously defined
            data.max_idx && setMaxVisibleTBatchID(data.max_idx);

        }
        catch (error) {
            console.error("Error: ", error);
        }
    }

    const load_followUps = async () => {
        setIsLoadingEntries(ps => ({...ps, followUp: true}));
        const response = await fetch(`${fetch_url}/load_follow_ups`, { 
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({user_token: sessionToken, encryption_key: encriptionKey})
        });

        // data is decoded (decoded in server side)
        const data = await response.json();
        console.log(typeof(data.content));
        console.log('FOLLOW: ');
        console.log(data);
        setFollowUpQuestions([...data.content]);
        setIsLoadingEntries(ps => ({...ps, followUp: false}));
    }
    
    const onAnswerFollowUpQuestion = (text) => {
        setActiveTab('today');
        setQuestionBeingAnswered(text);
        setTimeout(() => {
            if (textEntryRef.current) {
                textEntryRef.current.focus()
            }
        }, 300)
    }


    const removeFollowUpQuestion = (idx) => {
        setFollowUpQuestions(ps => ps.filter((_, i) => i !== idx));
        socket_ref.current.emit('remove_followUpQuestion', {user_token: sessionToken, idx: idx});
    };


    useEffect(() => {
        // using use Effect as we want it to be applied after painting
        const currentRef = messagesRefComponentsList.current[idxsDisplayedMinMax['idx_go_rel']];
        // undefined if the messages haven't been loaded yet, it is an empty list -- init useRef([]).
            
        // use timeOut to wait for the elements to be displayed, knowing the details e.g. their size of each.        
        if (currentRef  && isGoneExploringRef.current === false) {
            setIsGoneExploring(true);
        setTimeout(() => {
            // currentRef.current accesses the divisor
            currentRef.current.scrollIntoView({behavior: 'smooth', block: 'center'});
            currentRef.current.classList.add('glow-effect');

            setTimeout(() => {
                currentRef.current.classList.remove('glow-effect');
            }, 2000);

        }, 200) } else {
        } 
        

    }, [idxsDisplayedMinMax]);


    
    const load_batch_gone = async (is_top) => {
        console.log('load batch called with is_top: ', is_top);

        const response = await fetch(`${fetch_url}/load_batch_gone`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                encryption_key: encriptionKey, 
                user_token: sessionToken,
                min_abs: idxsObjectRef.current.min_abs,
                max_abs: idxsObjectRef.current.max_abs,
                is_top: is_top,
                b_size: 20,
            })
        });

        const data = await response.json();
        const decrypted_mgs = data.messages.map((item) => {
            const redeable_entry = decryptMessage(encriptionKey, ...item.entry.split('_'));
            const readable_models = decryptMessage(encriptionKey, ...item.models.split('_'));
            let models;
            try {
                models = eval(`(${readable_models})`)
            } catch (e) {
                models = readable_models;
            }
            return {...item, entry: redeable_entry, models: models};
        });
        console.log(is_top ? 'going top' : 'going bottom' , 'new batch info')
        setIsRockBottomTop({bottom: data.isBottomComplete, top: data.isTopComplete});
        setIdxsDisplayedMinMax({...idxsObjectRef.current, min_abs: data.idx_min, max_abs: data.idx_max});
        is_top ? setMessages([...decrypted_mgs, ...messagesListRef.current]) : setMessages([...messagesListRef.current, ...decrypted_mgs]);
    }

    const go_to_date = async (date) => {
        isGoneExploringRef.current = false
        const response = await fetch(`${fetch_url}/jump_to_entry`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                encryption_key: encriptionKey, 
                user_token: sessionToken,
                date: date,
                explore_batch_size: 80,
            })
        });

        const data = await response.json();
        console.log(data);

        if (data.error_msg) {
            console.log(date.error_msg);
        }

        else {
        
        const decrypted_mgs = data.messages.map((item) => {
            const redeable_entry = decryptMessage(encriptionKey, ...item.entry.split('_'));
            const readable_models = decryptMessage(encriptionKey, ...item.models.split('_'));
            let models;
            try {
                models = eval(`(${readable_models})`)
            } catch (e) {
                models = readable_models;
            }
            return {...item, entry: redeable_entry, models: models};
        });
            setMessages([...decrypted_mgs]);
            setIsRockBottomTop({bottom: data.isBottomComplete, top: data.isTopComplete});
            setIdxsDisplayedMinMax({min_abs: data.idx_min, max_abs: data.idx_max, idx_go_rel: data.idx_go_rel});
        }

    }



    const go_to_entry = async (p_id) => {
        isGoneExploringRef.current = false
        const response = await fetch(`${fetch_url}/jump_to_entry`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                encryption_key: encriptionKey, 
                user_token: sessionToken,
                p_id: p_id,
                explore_batch_size: 80,
            })
        });

        const data = await response.json();

        const decrypted_mgs = data.messages.map((item) => {
            const redeable_entry = decryptMessage(encriptionKey, ...item.entry.split('_'));
            const readable_models = decryptMessage(encriptionKey, ...item.models.split('_'));
            let models;
            try {
                models = eval(`(${readable_models})`)
            } catch (e) {
                models = readable_models;
            }
            return {...item, entry: redeable_entry, models: models};
    });

    setIsRockBottomTop({bottom: data.isBottomComplete, top: data.isTopComplete});
    setMessages([...decrypted_mgs]);
    setResultsList([]);
    setSearchBarQuery('');   
    setIdxsDisplayedMinMax({min_abs: data.idx_min, max_abs: data.idx_max, idx_go_rel: data.idx_go_rel});
    };


    const isContentLoading = useRef(false);
    const handleScroll = async (event) => {
        const { scrollTop, clientHeight, scrollHeight } = event.target;
        // scrollHeight: complete height of the divisor (pixels)
        // clientHeight: visible content of the divisor (pixels)
        // scrollTop: number of pixels scrolled down from the top (pixels)

        // + 1 pixels is necessary, if not the condition is not met when the scroll is at the bottom
        const isAtBottom = Math.round(scrollTop + clientHeight + 1) >= scrollHeight;
        const isApproxBottom = Math.round(scrollTop + 50 + clientHeight) >= scrollHeight;
        
        setIsScreenAtBottom(isApproxBottom);

        const isAtTop = scrollTop === 0;
        const isBottomComplete = rockBottomRef.current.bottom;
        if (isBottomComplete) isGoneExploringRef.current = false;

        const isTopComplete = rockBottomRef.current.top;

        if (isAtTop && !isGoneExploringRef.current) {
            
            setDummyBatchLoaderScrollUp([...dummyBatchLoader]);
            console.log('loading more messages!')
        };
        
        if (isGoneExploringRef.current && isAtTop && !isTopComplete && !isContentLoading.current) {
            isContentLoading.current = true;
            await load_batch_gone(true);
            setScrollingUp(true);
            setOldScrollHeight(chatMessagesRef.current.scrollHeight);
            isContentLoading.current = false;
        }

        if (isGoneExploringRef.current && isAtBottom && !isBottomComplete && !isContentLoading.current) {
            isContentLoading.current = true;            
            await load_batch_gone(false);
            setOldScrollHeight(chatMessagesRef.current.scrollHeight);
            setScrollingUp(false);
            isContentLoading.current = false;

        }
        
    };

    const scrollToBottom = (behavior) => {

        if (isGoneExploringRef.current) {
            console.log('I was exploring and now Im not');
            setIsGoneExploring(false);
            setMessages([]);
            setDummyBatchLoaderScrollUp([...dummyBatchLoader]);
        }

        if (messagesEndRef.current){
            messagesEndRef.current.scrollIntoView({behavior: behavior ? behavior : 'smooth', block: 'start'})
        }

    }
    
    useEffect(() => {  

        const reloadContentAsync = async () => {
        
            if (activeTab === 'today') {        
                // ScrollToBottom is being performed after the messages are loaded. See useEffect([messages])
                setMessagesHeight();

                const msgDiv = chatMessagesRef.current; 
                if (msgDiv) {
                    msgDiv.addEventListener('scroll', handleScroll);
                }
            }

            const dropTabs =  await reload_content(activeTab);
            emptyContainers(dropTabs)

            if (activeTab === 'today') {
                // Return function for cleanup
                const msgDiv = chatMessagesRef.current; 
                return () => {
                    if (msgDiv) {
                        msgDiv.removeEventListener('scroll', handleScroll);
                    }
                };

            };            
        };

        reloadContentAsync();      

    }, [activeTab]);
    

    useEffect(()=>{
        
        if (chatMessagesRef.current) {
            setOldScrollHeight(chatMessagesRef.current.scrollHeight);
            setScrollingUp(true);
        }        
        encriptionKey && load_messages();

    },[dummyBatchLoader])

    const getSearchResults = async (query) => {
        if (!query) {
            // Do nothing if query is not valid
            console.log('query is empty')
            return;
        }

        try {
            setIsLoadingEmbeddingSearch(true);
            const response = await fetch(`${fetch_url}/populate_search_journal`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                encryption_key: encriptionKey, 
                user_token: sessionToken,     
                query: query,
                num_k: 100,
                filter_time_delta: filterSearchSelectedTimeDelta
            })
            });
    
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            const data = await response.json();
            const results = data.results;
            setResultsList([...results]);
            setIsLoadingEmbeddingSearch(false);
    
            console.log(results);
        }
        catch (error) {
            console.error("Error: ", error);
        }
    };
    
    async function encryptMessage(message) {

        // Generate a random IV
        const iv = crypto.getRandomValues(new Uint8Array(16));
      
            // Import the key
            const cryptoKey = await crypto.subtle.importKey(
                'raw',
                Uint8Array.from(Buffer.from(encriptionKey, 'hex')).buffer,
                { name: 'AES-CBC', length: 128 },
                false,
                ['encrypt']
            );

            // Encrypt the message
        const encoder = new TextEncoder();
        const encodedMessage = encoder.encode(message);

        const encryptedMessage = await crypto.subtle.encrypt(
                { name: 'AES-CBC', iv },
                cryptoKey,
                encodedMessage
            );
    
            // Convert encrypted message and IV to hex
            const encryptedMessageHex = Buffer.from(new Uint8Array(encryptedMessage)).toString('hex');
            const ivHex = Buffer.from(iv).toString('hex');

            return [ivHex, encryptedMessageHex];
    
    }
    
    
    const load_archive = async () => {
        setIsLoadingEntries(ps => ({...ps, archDay: true}));
        const response = await fetch(`${fetch_url}/load_archive_entries`, {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({user_token: sessionToken})
        });
        const data = await response.json();
        const columns = data.encripted_columns
        const mgs = JSON.parse(data.messages);

        const decrypted_mgs = mgs.map((item)=>{
            const new_obj = {...item}
            columns.map((col) => {
                const [iv, text] = item[col].split('_');
                const readable_text = decryptMessage(encriptionKey, iv, text);
                new_obj[col] = readable_text;
            }
            );
            return new_obj;
        });
        setArchEntries([...decrypted_mgs]);
        archDivRefs.current = decrypted_mgs.map((_, i)=>{
            return createRef();
        });
        setIsLoadingEntries(ps => ({...ps, archDay: false}));
    };


    const load_archive_week = async () => {
            setIsLoadingEntries(ps => ({...ps, archWeek: true}));

        const response = await fetch(`${fetch_url}/load_archive_weekly`, {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({user_token: sessionToken}) 
        });

        const data = await response.json();
        const columns = data.encripted_columns
        const mgs = JSON.parse(data.messages);

        const decrypted_mgs = mgs.map((item)=>{
            const new_obj = {...item}
            columns.map((col) => {
                const [iv, text] = item[col].split('_');
                const readable_text = decryptMessage(encriptionKey, iv, text);
                new_obj[col] = readable_text;
            }
            );
            return new_obj;
        });

        setArchWEntries([...decrypted_mgs]);
        setIsLoadingEntries(ps => ({...ps, archWeek: false}));
    }

    const load_archive_month = async () => {
        setIsLoadingEntries(ps => ({...ps, archMonth: true}));
        const response = await fetch(`${fetch_url}/load_archive_monthly`, {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({user_token: sessionToken})
        });

        const data = await response.json();
        const columns = data.encripted_columns
        const mgs = JSON.parse(data.messages);

        const decrypted_mgs = mgs.map((item)=>{
            const new_obj = {...item}
            columns.map((col) => {
                const [iv, text] = item[col].split('_');
                const readable_text = decryptMessage(encriptionKey, iv, text);
                new_obj[col] = readable_text;
            }
            );
            return new_obj;
        });

        setArchMEntries([...decrypted_mgs]);
        setIsLoadingEntries(ps => ({...ps, archMonth: false}));
    }


    const load_models = async () => {
        setIsLoadingEntries(ps => ({...ps, models: true}));
        const response = await fetch(`${fetch_url}/load_models`, {
            method: "POST",
            headers: {"Content-type": "application/json"},
            body: JSON.stringify({user_token: sessionToken})
        });

        const data = await response.json();
        const data_enc = JSON.parse(data.data_enc);
        const columns = data.encripted_columns;

        const decrypted_models = data_enc.map((item)=>{
            const new_obj = {...item}
            columns.map((col) => {
                const [iv, text] = item[col].split('_');
                const readable_text = decryptMessage(encriptionKey, iv, text);
                try {
                new_obj[col] = (col === 'desc_archive' || col === 'alias') ? JSON.parse(readable_text) : readable_text;
                } catch (e) {
                    new_obj[col] = readable_text;
                }
            }
            );
            return new_obj;
        })

        setModelsList(decrypted_models);
        setIsLoadingEntries(ps => ({...ps, models: false}));
    }

    
    const logout = () => {
        setActiveTab('login');
        setUserEmail('');
        setIsBetaUser(false);
        setSessionToken(null);
        setUsername(null);
        googleLogout();
        // empty all previous data
    }


    const closeOpenHtmlTags = (inputStr) =>  {

        const  removeUnfinishedTags = (inputString) => {
            const pattern = /<\w/g; // Global search for '<' followed by any word character
            let matches;
            while ((matches = pattern.exec(inputString)) !== null) {
                const matchStart = matches.index;
                // Check if the character after the match is not '>'
                if (inputString[matchStart + 2] !== '>') {
                    // Remove the unmatched tag start
                    inputString = inputString.substring(0, matchStart) + inputString.substring(matchStart + 2);
                    // Reset the lastIndex to deal with the modified inputString
                    pattern.lastIndex = 0;
                }
            }
            return inputString;
        }

        let tags = []; // Stack to keep track of open tags
        let i = 0;
        while (i < inputStr.length) {
            if (inputStr.substring(i, i+3) === "<p>") {
                tags.push("p");
                i += 3;
            } else if (inputStr.substring(i, i+3) === "<b>") {
                tags.push("b");
                i += 3;
            } else if (inputStr.substring(i, i+4) === "</p>") {
                if (tags.length > 0 && tags[tags.length - 1] === "p") {
                    tags.pop();
                }
                i += 4;
            } else if (inputStr.substring(i, i+4) === "</b>") {
                if (tags.length > 0 && tags[tags.length - 1] === "b") {
                    tags.pop();
                }
                i += 4;
            } else {
                i++; // Increment to move through the string
            }
        }
    
        // Append missing closing tags
        while (tags.length > 0) {
            let tag = tags.pop();
            if (tag === "p") {
                inputStr += "</p>";
            } else if (tag === "b") {
                inputStr += "</b>";
            }
        }
    
        return removeUnfinishedTags(inputStr);
    }



    const generateAnswerArchive = (data) => {
        if (data.first) {
            setUnfinishedAGeneration("");
            const new_content = {current: "", tag: data.metadata.tag, date: data.metadata.date};
            const generated_content = [...genAContentRef.current, new_content];
            setGeneratedAContent(generated_content);

        }

        if (data.last) {
            // data tags: (answer_delta, full_answer, first, last, metadata: dict ['tag', 'date'])
            const new_content = {current: data.full_answer, tag: data.metadata.tag, date: data.metadata.date};
            const generated_content = [...genAContentRef.current.slice(0,-1), new_content];
            setGeneratedAContent(generated_content);
            
        }
        setUnfinishedAGeneration(closeOpenHtmlTags(data.full_answer));
    }


    useEffect(()=>{
        document.body.className = isDarkMode ? 'dark-mode' : 'light-mode'
    }, [isDarkMode])
    
    useEffect(() => {        
        /* Load the info when the page is reloaded */ 
        if (!socket_ref.current) {
            true ? (
                socket_ref.current = io(root_server_url, 
                    {
                        transports: ["polling"], // Start with HTTP Long-Polling
                        extraHeaders: {
                          "ngrok-skip-browser-warning": "true" // Intended to bypass the interstitial page
                        }
                      })
            ) : socket_ref.current = io(root_server_url);

        }  
        // Filling messages and archive entries when the page is refreshed
        // can be optimized to load only one of them. Do not fetch on every tab change.

        socket_ref.current.on('set_token_null', () => logout());
        socket_ref.current.on('update_streak', (data) => setStreak(data.streak))
        socket_ref.current.on('appendTokensSummaries', generateAnswerArchive);
        


        if (sessionToken && encriptionKey){
            // content is being loaded when each tab is clicked
            // reload_content();
        } else {
            emptyContainers();
        }
        
        // function triggered when the component unmounts
        return () => {
            if (socket_ref.current) {
                socket_ref.current.disconnect();
                socket_ref.current = null;
            }
        } 
    }, []);

    
    useEffect(() => {
        // Update info when the user is set
        localStorage.setItem('sessionToken', sessionToken);

        if (sessionToken && encriptionKey){
            // content is being loaded when each tab is clicked
            // reload_content();
        } else {
            emptyContainers();
            // Encription key IS NOT required each time that the user logs in with google. uncomment for requiring the user to input it again.
            // setEncriptionKey('');
        }

    }, [sessionToken]);

    
    useEffect(() => {
        const handleUnload = () => {
            sessionStorage.setItem('windowScrollPos', window.scrollY);
        };

        window.addEventListener('beforeunload', handleUnload);

        const savedPosition = sessionStorage.getItem('windowScrollPos');

        if (savedPosition) {
            setTimeout(() => {
                window.scrollTo(0, parseInt(savedPosition, 10));     
            }, 2000)
            
        }

        // clean
        return () => {
            window.removeEventListener('beforeunload', handleUnload);
        };

    }, [])

    // Day in focus reload

    useEffect(() => {
        sessionStorage.setItem('displayedAID', displayedAID)
    }, [displayedAID])

    useEffect(() => {
        sessionStorage.setItem('isModalAOpen', String(isModalAOpen))
    }, [isModalAOpen])

    // reload the imgs when an image is added
    useEffect(() =>{
        sessionStorage.setItem('editingArchEntry', JSON.stringify(editingArchEntry))
    }, [editingArchEntry])
    


    useEffect(() => {
        /* Refresh memory: save the activeTab to localStorage whenever it changes */
        sessionStorage.setItem('activeTab', activeTab);
    }, [activeTab]);

    
    useEffect(() => {
        /* Refresh memory: save the activeTab to localStorage whenever it changes */
        sessionStorage.setItem('archEntries', archEntries);
    }, [archEntries]);    

    useEffect(()=>{
        localStorage.setItem('username', username);
        localStorage.setItem('userEmail', userEmail);
        localStorage.setItem('isBetaUser', isBetaUser);
        localStorage.setItem('streak', streak)
    }, [username, userEmail, streak, isBetaUser])



    useEffect(() => {
        // Scroll to the last seen message

        const newLen = messages.length;
        const delta = newLen - lenMessages;
        if (messagesEndRef.current && delta > 5)  {
            const newScrollHeight = chatMessagesRef.current.scrollHeight;
            const deltaHeights = newScrollHeight - oldScrollHeight; 

            if (scrollingUp) {
                chatMessagesRef.current.scrollTop = deltaHeights;
            } else {
                chatMessagesRef.current.scrollTop = oldScrollHeight - chatMessagesRef.current.clientHeight;
            };
            
        }
        setLenMessages(newLen);

        if (editingDate) {
            const updatedArch = archEntries.map((arch) => {
                if (arch.date === editingDate){
                return {...arch, update_available: true};
                }
                return arch;
            });
            setArchEntries([...updatedArch]);
        }

    
    }, [messages, editingDate]) // apply this effect each time messages is updated

    
    async function addEntry (message) {

        if (questionBeingAnswered) {
            socket_ref.current.emit('edit_follow_up_questions', {user_token: sessionToken, question: questionBeingAnswered, answer: message, encryption_key: encriptionKey})
        }

        message = questionBeingAnswered ? `<p>${questionBeingAnswered}🤔</p>${message}` : message;
        questionBeingAnswered && setQuestionBeingAnswered('');

        const [formatted_date, formatted_time] = getCurrentDateFormatted()
        
        
        const [iv, encMessage] =  await encryptMessage(message);

        // TODO: Doublecheck if this decreases the image, if so simply send it without resizing it in advance. 
        const imgsSend = await Promise.all(attachedImgs.map(img => resizeImage(img, 1980)));        

        const new_entry_back = {
            entry: `${iv}_${encMessage}`,
            date: formatted_date,
            time: formatted_time,
            images: imgsSend
        };
        
        if (!isGoneExploringRef.current) {
            const new_entry_front = {...new_entry_back, entry: message, models: {}};
            setMessages([...messages, new_entry_front]);

        }

        socket_ref.current.emit('add_entry', {...new_entry_back, user_token: sessionToken, 'encription_key': encriptionKey});
        setAttachedImgs([]);
    };
    

    const resizeImage = (base64Str, maxDimension) => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.src = base64Str;
            img.onload = () => {
                const ratio = Math.min(maxDimension / img.width, maxDimension / img.height);
                const canvas = document.createElement('canvas');
                if (ratio < 1) {
                    const width = ratio * img.width;
                    const height = ratio * img.height;
                    canvas.width = width;
                    canvas.height = height;
                    const ctx = canvas.getContext('2d');
                    ctx.drawImage(img, 0, 0, width, height);
                    return resolve(canvas.toDataURL());
                } else {
                    return resolve(base64Str);
                }
            };
            img.onerror = error => reject(error);
        });

    };
    
    const handleImageChange = async (e) => {
        const file = e.target.files[0];
        if (file && file.type.startsWith("image/")) {
            const reader = new FileReader();
            reader.onloadend = async () => {
                // const resizedImage = await resizeImage(reader.result, 1980);
                const imgs = [...attachedImgs, reader.result];
                setAttachedImgs(imgs);
            };
            reader.readAsDataURL(file);
        }
    }

    function getMainPartOfLink(link) {
        const url = new URL(link);
        const mainPart = `${url.protocol}//${url.hostname}`;
        return String(mainPart);
      }

    const handlePaste = (e) => {
        e.preventDefault();
    
        const items = (e.clipboardData || window.clipboardData).items;
    
        // Process any files first (e.g., images)
        for (const item of items) {
            if (item.kind === 'file') {
                const file = item.getAsFile();
                if (file && file.type.startsWith("image/")) {
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        const imgs = [...attachedImgs, reader.result]; // Assuming attachedImgs is part of your component's state
                        setAttachedImgs(imgs); // Assuming this updates your state
                    };
                    reader.readAsDataURL(file);
                }
            }
        }
    
        // Process text including URLs
        const text = (e.clipboardData || window.clipboardData).getData('text');
        if (text) {
            // Simple URL detection
            const urlRegex = /(https?:\/\/[^\s]+)/g;
            if (urlRegex.test(text)) {
                const newText = text.replace(urlRegex, (url) => `<a href="${url}" target="_blank">${getMainPartOfLink(url)}</a>`);
                insertHtmlAtCaret(newText);
            } else {
                // If not a URL, insert text normally
                insertTextAtCaret(text);
            }
        }
    };

    // Helper function to insert text at caret position
    function insertTextAtCaret(text) {
        document.execCommand("insertText", false, text);
    }

    // Helper function to insert HTML at caret position
    function insertHtmlAtCaret(html) {
        const range = document.getSelection().getRangeAt(0);
        range.deleteContents();
        const div = document.createElement("div");
        div.innerHTML = html;
        const frag = document.createDocumentFragment()
        let child;
        while ((child = div.firstChild)) {
            frag.appendChild(child);
        }
        range.insertNode(frag);
    }
    
    useEffect(()=>{

        if (chatMessagesRef.current && imgsPreviewContainerRef.current) {
            const currentH_preview = imgsPreviewContainerRef.current.offsetHeight;            
            const currentH_inputBar = textEntryRef.current.offsetHeight;
            let newH = `calc(100vh - ${currentH_preview}px - 150px - ${currentH_inputBar}px)`;
            chatMessagesRef.current.style.height = newH;
        };
        
    },[attachedImgs]);


    const handleImageChangeArchive = (e) => {
        const file = e.target.files[0];
        if (file && file.type.startsWith("image/")) {
            const reader = new FileReader();
            reader.onloadend = async () => {
                const imgs = [...attachedAImgs, reader.result];
                setCurrentAImage(imgs);
    
                // Now send the updated image list to the server
                const response = await fetch(`${fetch_url}/upload_img`, {
                    method: "POST",
                    headers: {"Content-Type": "application/json"},
                    body: JSON.stringify({idx: displayedAID, 
                                          images: imgs, 
                                          user_token: sessionToken})
                });
                const data = await response.json();
                const newEditingEntry = {...editingArchEntry}
                newEditingEntry["images"] = data.current_imgs;
                
                const newArchSet = [...archEntries]
                newArchSet[displayedAID] = newEditingEntry
                
                setEditingArchEntry(newEditingEntry);
                setNumDisplayedImgs(numDisplayedImgs + 1);
                // This need to set up to upadate ArchEntries as it updated EditingArchEntry (see handleSubmissionEditAEntry)
                setArchEntries(newArchSet);
                
                // Clear the current images if needed
                setCurrentAImage([]);
            };
            reader.readAsDataURL(file);
        }
    };
    

    const setDateOnMessage = (date, time, index) => {        
        const newMessages = [...messages];
        newMessages[index] = {...newMessages[index], date: date, time: time};
        setMessages(newMessages);
    }

    const handleEditMessage = async (editIdx, editedText, dateorTimeChanged) => {
        // editText is: editContentRef.current.innerHTML
        let content_changed = false;
        const messages_new = messages.map((msg, idx) => {
            if (idx === editIdx) {
                console.log('Save has been called, they are: ', editedText !== msg.entry ? 'different' : 'equal');
                if (editedText !== msg.entry) {
                    content_changed = true;
                    return {...msg, entry: editedText};
                }
                return msg
            }
            return msg
        })
        setEditingDate(messages[editIdx].date);
        setMessages(messages_new);

        const [iv, encText] = await(encryptMessage(editedText))

        if (content_changed || dateorTimeChanged) {
            socket_ref.current.emit('update_entry', {
                rel_idx: editIdx, 
                entry: `${iv}_${encText}`, 
                user_token: sessionToken,
                encryption_key: encriptionKey,
                time: messages[editIdx].time,
                date: messages[editIdx].date,
                len_messages: messages.length
            })
        }                
    }
 

    function showArchiveEntry(entryID) {
        setDisplayedAID(entryID);
        setIsModalAOpen(true);

        let selected_entry;

        if (aActiveTab === 'daily') {
            selected_entry = archEntriesRef.current[entryID]
        }

        else if (aActiveTab === 'weekly') {
            selected_entry = archWEntriesRef.current[entryID]
        }

        else if (aActiveTab === 'monthly') {
            selected_entry = archMEntriesRef.current[entryID]
        }

        setEditingArchEntry(selected_entry);
    }


    function editModel(model_id, newModel) {
        console.log('editing model id: ', model_id);
        let new_model;
        const newModels = modelsList.map(m => {
            if (model_id === m.id) {
                m.desc_archive[[m.desc_last_update_date]] = newModel.description;
                new_model = {...m, ...newModel};
                return new_model;
            }
            return m;
        });
        setModelsList([...newModels]);

        // encript model
        socket_ref.current.emit('edit_model', {
                                               user_token: sessionToken, 
                                               model: new_model,
                                               encryption_key: encriptionKey
                                            })
    }

    function removeModel(model_id) {
        setModelsList( ps => ps.filter(m => m.id != model_id));
        socket_ref.current.emit('remove_model', {model_id: model_id, user_token: sessionToken})
    }

    function replaceBrakeLines(text){
        return text.replace(/(?:\r\n|\r|\n)/g, '<br>');
    }

    function htmlToCustomMd(htmlString) {
        // Remove empty lines and join back
        htmlString = htmlString.split('\n').filter(x => x).join('\n');
        let markdown = htmlString.replace(/<ul>/gi, '');
        markdown = markdown.replace(/<\/ul>/gi, '');
        markdown = markdown.replace(/<li>/gi, '- ');
        markdown = markdown.replace(/<\/li>/gi, '');
      
        const newLines = [];
        markdown.split('\n').forEach(line => {
          if (!line) return;
          line = line.trim();
          if (line.startsWith('- ')) {
            line = `<p>${line}</p>`;
          }
      
          newLines.push(line);
        });
      
        return newLines.join('\n').trim();
      }
      
    function customMdToHtml(text) {
        
        const newArr = text.split('</p>').map((m, idx)=>{
            if (!m.startsWith('\n') && idx > 0) {
                return '\n'+m
            };
            return m;
        })
        
        const textMod = newArr.join('</p>');

        const newLines = [];
        let isBulletPoints = false;
      
        textMod.split('\n').forEach(line => {
          
        let line_;
        if (line.trim().startsWith('<p>-')) {
            line_ = `<li>${line.replace('</p>', '').trim().substring(5)}</li>`;
        }

        if (line.trim().startsWith('<p><b>-')){
            line_ = `<li>${line.replace('</p>', '').replace('<p>', '').replace('- ', '').trim()}</li>`;
        }

        if (line_) {
            if (!isBulletPoints) {
              newLines.push('<ul>');
              isBulletPoints = true;
            }
          } else {
            if (isBulletPoints) {
              newLines.push('</ul>');
              isBulletPoints = false;
            }
          }

          line_ ? newLines.push(line_) : newLines.push(line);

        });
      
        if (isBulletPoints) {
          newLines.push('</ul>');
        }
      
        return newLines.join('\n');
    }
    
    const generateFollowUpQuestionsArch = async () => {
        setIsGeneratingQuestions(true);
        
        const response = await fetch(`${fetch_url}/generate_follow_up_questions`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({user_token: sessionToken, encryption_key: encriptionKey, arch_entry: editingArchEntry})
        });
        
        const data = await response.json();
        const fuQs = JSON.parse(data.response);
        setFollowUpQuestions([...followUpQuestions,  ...fuQs]);
        setIsGeneratingQuestions(false);
        setTimeout(()=>setActiveTab('question'), 200);
    };

    const markQuestionAsSeen = (source_id) => {
        socket_ref.current.emit('mark_question_as_seen', {user_token: sessionToken, source_id: source_id});
    };

    const generateFollowUpQuestions = async (idx) => {
        setIsGeneratingQuestions(true);
        
        const response = await fetch(`${fetch_url}/generate_follow_up_questions`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({user_token: sessionToken, encryption_key: encriptionKey, entry_dec: messages[idx], entry_idx: idx})
        });
        
        const data = await response.json();
        const fuQs = JSON.parse(data.response);
        setFollowUpQuestions([...followUpQuestions,  ...fuQs]);
        setIsGeneratingQuestions(false);
        setTimeout(()=>setActiveTab('question'), 200);
    };


    const analyzeJournalEntry = async (text, prompt_tag) => {
        setAnalyzedData('')
        setIsAnalyzingEntry(true);
        const response = await fetch(`${fetch_url}/fetch_analysis`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({user_token: sessionToken, entry: text, prompt_tag: prompt_tag})
            });
        
        const data = await response.json();
        setIsAnalyzingEntry(false);
        
        setAnalyzedData(data.response);

    }

    const onCloseModalAnalysis = () => {
        setAnalyzedData('');
    }
      
      

    async function handleSubmitEditAEntry(text, category) {
        const newArchEntry = {...editingArchEntry};
        newArchEntry[category] = text;
        setEditingArchEntry(newArchEntry);
        
        let newArchEntries;

        if (aActiveTab === 'daily'){
            newArchEntries = [...archEntries]
        }

        if (aActiveTab === 'weekly'){
            newArchEntries = [...archWEntries]
        }

        if (aActiveTab === 'monthly'){
            newArchEntries = [...archMEntries]
        }

        newArchEntries[displayedAID] = newArchEntry
        
        if (aActiveTab === 'daily'){
            setArchEntries(newArchEntries);
        }

        else if (aActiveTab === 'weekly') {
            setArchWEntries(newArchEntries);
        }

        else if (aActiveTab === 'monthly') {
            setArchMEntries(newArchEntries);
        }

        

        if (category === 'summary' && aActiveTab === 'daily') {
            // set notification pop-up on the weeks tab

            const new_weekly = archWEntries.map((entry)=>{
                if (entry.year_cw === newArchEntry.year_cw){
                    return {...entry, update_available: true}
                }
                return entry                
            })

            setArchWEntries(new_weekly);

        }
        
        // close the modal
        const [ivHex, encHex] = await encryptMessage(text);

        // TODO: Ensure that all column on category needs to be encripted
        socket_ref.current.emit('update_archive_entry', 
            {idx: displayedAID, 
            text: `${ivHex}_${encHex}`, 
            category: category, 
            user_token: sessionToken,
            active_tab: aActiveTab
        })
        
    }

    const onDeleteEntryArchive = () => {
        setIsDeletingEntry(false);
        handleRemoveEntry(aActiveTab);
    }

    const handleRemoveEntry = (tag) => {

        const tag_to_state = {
            'daily': [archEntries, setArchEntries], 
            'weekly': [archWEntries, setArchWEntries], 
            'monthly': [archMEntries, setArchMEntries]
        }

        const [arr, func] = tag_to_state[tag];
        const newArchEntries = [...arr];
        newArchEntries.splice(displayedAID, 1);
        func(newArchEntries);
        setIsModalAOpen(false);

        socket_ref.current.emit(`remove_archive_${tag}`, {idx: displayedAID, user_token: sessionToken})

    }


    function removeFromStr(input_str, remove_part){
        return input_str.split(",")
               .filter(item => item !==remove_part)
               .join(",");
    }

    function removeDisplayedImg (displayed_img_name){
        
        const currentEntry = {...editingArchEntry};
        let out = removeFromStr(currentEntry.images, displayed_img_name);
        currentEntry.images = out;

        setEditingArchEntry(currentEntry);
        const currentArchEntries = [...archEntries]
        currentArchEntries[displayedAID] = currentEntry;
        setArchEntries(currentArchEntries);

        //set the change in the xlsx
        socket_ref.current.emit('modify_images', {imgs: out, 
                                                user_token: sessionToken, 
                                                idx: displayedAID,
                                                removed: displayed_img_name});
        console.log('removing img: ', displayed_img_name)
        console.log('imgs displ: ', out)

    }

    const removeAliasFromEntry = (alias, p_id, idxEntry, idModel) => {
        const newMessage = {...messages[idxEntry]};
        
        // if there is only one entry in the array drop the k,v pair otherwise drop only the v
        if (newMessage.models[alias].length > 1){
            delete newMessage.models[alias][idModel];
        } else {
            delete newMessage.models[alias];
        }

        const newMessages = messages.map((item, idx) => {
            if (idxEntry === idx) {
                return newMessage;
            }
            return item;
        });

        setMessages(newMessages);

        // TODO: Remove alias from entry on the server 
        socket_ref.current.emit('removeAliasFromEntry', {alias: alias, p_id: p_id, encryption_key: encriptionKey, idxEntry: idxEntry, user_token: sessionToken, len_messages: messages.length})


    }



    const removeEntry = (idx) => {
        console.log(idx);
        const mgs_copy = [...messages]
        mgs_copy.splice(idx,1)
        setMessages(mgs_copy)

        socket_ref.current.emit('remove_entry', {idx_rel: idx, mgs_rel_len: messages.length, user_token: sessionToken}) 
    }

    // media recorder: 
    const [isRecordingVideo, setIsRecordingVideo] = useState(false);
    const [isRecordingAudio, setIsRecordingAudio] = useState(false);
    const [saveAudioBool, setSaveAudioBool] = useState(localStorage.getItem('saveAudioBool') === 'true' ? true : false);

    useEffect(() => {
        localStorage.setItem('saveAudioBool', saveAudioBool);
    }, [saveAudioBool])

    const videoRef = useRef(null);
    const mediaRecorderRef = useRef(null); // media recorder object
    const recordedBlobsRef = useRef([]);
    
    const [selectedLanguage, setSelectedLanguage] = useState('english');
    
    useEffect(() => {
        // Cleanup function to stop media stream
        return () => {
          // why dont clean audio? 
            if (videoRef.current && videoRef.current.srcObject) {
                videoRef.current.srcObject.getTracks().forEach(track => track.stop());
            }
        };
    }, []);

    const setupRecorder = async (stream, mimeType) => {
        recordedBlobsRef.current = [];
        const options = mimeType === "video/webm" ? { mimeType } : {};
        mediaRecorderRef.current = new MediaRecorder(stream, options);

        mediaRecorderRef.current.ondataavailable = (event) => {
            if (event.data && event.data.size > 0) {
                recordedBlobsRef.current.push(event.data);
            }
        };

        
        mediaRecorderRef.current.onstop = async () => {
            const blob = new Blob(recordedBlobsRef.current, { type: mimeType });
            const form = new FormData();

            const [date, time] = getCurrentDateFormatted();
            form.append('file', blob);
            form.append('type', mimeType.includes('video') ? 'video' : 'audio');
            form.append('language', selectedLanguage);
            form.append('timestamp', `${date}_${time.replace(':','')}`)
            form.append('is_save_file', saveAudioBool)
            form.append('user_token', sessionToken)
            form.append('encryption_key', encriptionKey)

            try {
                
                const response = await fetch(`${fetch_url}/save_and_transcribe`, {
                    method: 'POST',
                    body: form
                });
                const data = await response.json();                
                textEntryRef.current.innerHTML += ` ${data.transcript}`;
                setIsTranscribingAudio(false);
                // Set the cursor at the end and focus after transcribe, so that I don't have to click on the field to keep writing
                let div = textEntryRef.current
                const range = document.createRange();
                range.selectNodeContents(div);
                range.collapse(false); // collapse the selection setting the cursor at the end of it
                const selection = window.getSelection();
                selection.removeAllRanges(); //remove previous selection
                selection.addRange(range); // place the cursor on the specified location and focus

            } catch (error) {
                console.error("Error: ", error);
            }
        };
    };

    const handleToggleVideo = async () => {
        if (isRecordingVideo) {
            mediaRecorderRef.current.stop();
            if (videoRef.current) {
                videoRef.current.srcObject = null;
            }
            setIsRecordingVideo(false);
        } else {
            const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
            if (videoRef.current) {
                videoRef.current.srcObject = stream;
            }
            await setupRecorder(stream, 'video/webm');
            mediaRecorderRef.current.start();
            setIsRecordingVideo(true);
        }
    };

    const handleToggleAudio = async () => {
        if (isRecordingAudio) {
            mediaRecorderRef.current.stop();
            setIsRecordingAudio(false);
            setIsTranscribingAudio(true);
        } else {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            await setupRecorder(stream, 'audio/wav');
            mediaRecorderRef.current.start();
            setIsRecordingAudio(true);
        }
    };


    const checkForUpdates = async () => {
        const response = await fetch(`${fetch_url}/check_for_updates`, {
            method: 'POST',
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({user_token: sessionToken, encription_key: encriptionKey})
        });
        const data = await response.json();

        if (data.error) {
        console.log(data.error)
        }
    }

    const onTestKeySetting = async () => {

        const tentative_key = keyHolderRef.current.value;
        const response = await fetch(`${fetch_url}/test_key_setting`, {
            method: 'POST',
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({user_token: sessionToken, encryption_key: tentative_key})
        })

        const data = await response.json();
        console.log('key testing was: ', data.success);
        setEncriptionKey(data.success ? tentative_key : '');

    }

    const generateSummaries = () => {

        if (socket_ref.current) {
            socket_ref.current.emit('generate_summaries', 
            {user_token: sessionToken, 
            need: categoryNeedsUpdate, 
            encription_key: encriptionKey
            })
        } else {
            console.log('Socket is not initialized')
        }
    }

    const generate_text_daily = (date, idx) => {

        if (socket_ref.current) {
            socket_ref.current.emit('generate_content_date_day', {user_token: sessionToken, date: date, encryption_key: encriptionKey})
        } else {
            console.log('Socket is not initialized')
        }

        setIsModalGenerationAOpen(true);
        setInfoGenerationA({idx: idx});

        const newDaily = [...archEntries];
        newDaily[idx] = {...newDaily[idx], update_available: false};
        setArchEntries(newDaily);
    }

    const generate_text_weekly = (year_cw, idx) => {
        if (socket_ref.current) {
            socket_ref.current.emit('generate_content_week', {user_token: sessionToken, year_cw: year_cw, encryption_key: encriptionKey})
        } else {
            console.log('Socket is not initialized')
        }

        setIsModalGenerationAOpen(true);
        setInfoGenerationA({idx: idx});

        const new_weekly = [...archWEntries];
        new_weekly[idx] = {...new_weekly[idx], update_available: false};
        setArchWEntries(new_weekly);

    }

    const generate_text_monthly = (year_month, idx) => {
        if (socket_ref.current) {
            socket_ref.current.emit('generate_content_month', {user_token: sessionToken, year_month: year_month, encryption_key: encriptionKey})
        } else {
            console.log('Socket is not initialized')
        }

        setIsModalGenerationAOpen(true);
        setInfoGenerationA({idx: idx});

        const new_monthly = [...archMEntries];
        new_monthly[idx] = {...new_monthly[idx], update_available: false};
        setArchMEntries(new_monthly);
    }

    const onRetrieveGenContent = async () => {

        const response = await fetch(`${fetch_url}/get_generated_content`, {
            method: 'POST',
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({user_token: sessionToken, encryption_key: encriptionKey, tag: aActiveTab, idx: infoGenerationA.idx})
        });

        const data = await response.json();
        const entry = data.entry;
        console.log('DEBUG INFO')
        console.log(data.entry);

        const tag_to_state = {
            'daily': [archEntries, setArchEntries], 
            'weekly': [archWEntries, setArchWEntries], 
            'monthly': [archMEntries, setArchMEntries]
        }

        const [arr, func] = tag_to_state[aActiveTab];
        console.log('holaaa! im a log: ',arr, func)
        const newArchEntries = [...arr];
        newArchEntries[infoGenerationA.idx] = entry;
        console.log(entry);
        func(newArchEntries);
        
    }

    

    function OnFocusEntry({tag_category, title, onSubmitEdit}){
        const [isEditable, setIsEditable] = useState(false);
        const text_holder = useRef(null);

        const onEditClick = () => {
            setIsEditable(ps=>!ps);
            
        }

        const onSaveEntry = () => {
            const newMD_ = text_holder.current.innerHTML;
            const newHTML = customMdToHtml(newMD_);
            setIsEditable(ps=>!ps);           

            onSubmitEdit(newHTML, tag_category);

        }

        useEffect(()=>{
            isEditable && text_holder.current && text_holder.current.focus();
        },[isEditable])



        const onKeyDown = (e) => {
            replaceByLongDash(text_holder);
        }

        return (
        <div>
            {
            editingArchEntry[tag_category] && 
            <>
                <div className="title-and-buttons">
                    <h3>{title}: </h3>
                    <div>
                        <button className="edit-button" onClick={onEditClick}><span><FontAwesomeIcon icon={faPencilAlt}/></span></button>
                    </div>
                </div>
                <div ref={text_holder}
                     contentEditable={isEditable}
                     role='text-box'
                     aria-multiline='true'
                     onKeyDown={onKeyDown}
                     >
                {
                tag_category === 'bullets_html' ? (
                        isEditable ? parse(htmlToCustomMd(editingArchEntry[tag_category])) : parse(editingArchEntry[tag_category])

                    ) : (
                        isEditable ? parse(htmlToCustomMd(editingArchEntry[tag_category])) : parse(replaceBrakeLines(editingArchEntry[tag_category]))
                    )
                }

                </div>
                    {isEditable && 
                        <div>
                            <button onClick={onSaveEntry}>Save</button>
                            <button onClick={() => setIsEditable(p=>!p)}>Cancel</button>
                        </div>
                    }
            </>
            }
        </div>
    );
    }
 
    
    const  removeImg = (idx) => {
        const imgs = [...attachedImgs];
        imgs.splice(idx,1);
        setAttachedImgs(imgs);
    }

    const linkModel = (indexMessage, idxModel, currentAlias) => {
        console.log('Debug for linkModel function')
        
        let peopleID; 
        const updated_models = modelsList.map((m, idx) => {
            if (idx === idxModel) {
                peopleID = m.id;
                if (!m.alias.includes(currentAlias)) {
                    const newE =  {...m, alias: [...m.alias, currentAlias]}
                    return newE    
                }
                return m
            }
            return m
        })
        setModelsList(updated_models);

        const updated_messages = messages.map((m, idx) => idx === indexMessage ? {
            ...m,
            models: {
                ...m.models,
                [currentAlias] : [...(m.models[[currentAlias]] || []), peopleID]
            }
        } : m);

        setMessages(updated_messages);
        socket_ref.current.emit('addAliasToEntry', {idxEntry: indexMessage, idxModel: idxModel, alias: currentAlias, user_token: sessionToken, encryption_key: encriptionKey, len_messages: messages.length})
    }

    const addNewModel = (newModel, idxEntry) => {
        const new_models = [...modelListRef.current, newModel];
        setModelsList(new_models);
        console.log('adding model! ', newModel)
        socket_ref.current.emit('addNewModel', {new_model: newModel, encryption_key: encriptionKey, user_token: sessionToken, idx_entry: idxEntry ? idxEntry : -1, len_messages: messages.length})
    }


    const go_to_date_archive = (date) => {
        const [y,m,d] = date.split('-')
        const search_date = `${m}-${d}-${y}`
        
        
        if (archEntries && archDivRefs.current) {
            let idx = null;
            archEntries.map((e, i) => {
                if (e.date === search_date) {
                    idx = i;
                }
            })
            if (archDivRefs.current && archDivRefs.current[idx] && archDivRefs.current[idx].current) {
                archDivRefs.current[idx].current.scrollIntoView({behavior: 'smooth', block: 'start'});
            }
        }
    }


    const onGoToEntryAfterGeneration = async () => {
        await onRetrieveGenContent();
        setIsModalGenerationAOpen(!isModalGenerationAOpen);
        // TImeout makes that the command is executed after renderings
        setTimeout(()=>showArchiveEntry(infoGenerationA.idx), 0)
        setInfoGenerationA({idx: -1});
        setUnfinishedAGeneration("");
        setGeneratedAContent([]);
    }

    const onCloseEntryAfterGeneration = () => {
        onRetrieveGenContent();
        setIsModalGenerationAOpen(!isModalGenerationAOpen);
        setUnfinishedAGeneration("");
        setGeneratedAContent([]);
        setInfoGenerationA({idx: -1});
    }

    const replaceText = (content) => {
        let newContent = content;
        let updated = false;
        
        // Calculate replacements and updated content
        let substractCharacters = 0;
        if (content.includes('--')) {
            newContent = newContent.replace(/--/g, '—');
            updated = true;
            substractCharacters = 1;
        }
        if (content.includes('->')) {
            newContent = newContent.replace(/->/g, '→');
            updated = true;
            substractCharacters = 1;
        }

        if (content.includes(':)')) {
            newContent = newContent.replace(/:\)/g, '😀');
            updated = true;
            substractCharacters = 1;
        }

        if (content.includes(':(')) {
            newContent = newContent.replace(/:\(/g, '😞');
            updated = true;
            substractCharacters = 1;
        }

        return [newContent, updated, substractCharacters]

    }

    const replaceByLongDash = (ref) => {
        setTimeout(() => {
            const verbose = false;
            const selection = window.getSelection();
            verbose && console.log('<REPLACE LONG DASH LOG>')
            verbose && console.log('rangeCOunt: ', selection.rangeCount);
            if (!selection.rangeCount || !ref.current) return; // no selection available 
            // .innerText needs to be used here, as .textContent flattens the text 
            // .innerHTML converts the nodes into <div> instead of <text>
            // (node.nodeType == 3) referes to text nodes
            const content = ref.current.innerText;
            const [newContent, updated, substractCharacters] = replaceText(content ? content : '');
    
            if (updated) {
                const range = selection.getRangeAt(0);
                const currentNode = range.startContainer;

                // get the text of the current node to identify in which text to input the cursor for replacing it
                verbose && console.log('currentNode: ', currentNode);
                
                const nodeText = replaceText(currentNode.data)[0];
                verbose && console.log('nodeText: ', nodeText);
                // len of the content until the cursor position after inserting content. E.g. len('example--') when writing on 'example|text' 
                const startOffset = range.startOffset;
                // Calculate the new cursor position
                const newCursorPos = startOffset - substractCharacters;
    
                // Update the content
                ref.current.innerText = newContent;

                // Restore cursor position
                const newRange = document.createRange();
                const newSel = window.getSelection();
                let node;

                verbose && console.log('childNodes: ', ref.current.childNodes);
                for (node of ref.current.childNodes) {
                    if (node.nodeType == 3) { // TEXT_NODE
                        verbose &&  console.log('node.data: ', node.data);
                        if (newCursorPos <= node.length && nodeText == node.data) {
                            newRange.setStart(node, newCursorPos);
                            newRange.collapse(true)
                            break;
                        }
                    }
                }
                newSel.removeAllRanges();
                newSel.addRange(newRange);
                verbose &&  console.log('< // REPLACE LONG DASH LOG>')

            }
        }, 0);
    };

    const addAliasToList = (text) => {
        const alias = [...newModelAliasList, text];
        setCurrentAlias('');
        const seto = new Set(alias);
        setNewModelAliasList([...seto]);
    }

    const addModelInfo = () => {
        const description = newModelDescriptionRef.current.innerHTML;
        console.log(description);
        const newModel = {name: newModelName, description: description, alias: newModelAliasList}
        addNewModel(newModel);
        setIsAddingNewModel(false);
        setNewModelAliasList([]);
        setNewModelName('');

    }

    const simpleHandleKeyDownDesc = (e) => {
        replaceByLongDash(newModelDescriptionRef);
    }

    return (
        <div id='app-content' className='app-content' ref={appContentDivRef} style={fontSizeRef.current}>
            <div className = {isDarkMode ? 'tab-menu tab-menu-dark' : 'tab-menu'}>
                {/* Tab menu is position fixed i.e. indep from the parents div */}
                <div className='vertical-container'>
                    <div className='horizontal-container'>
                        
                            {!isSearchingToday && !isSearchingModels &&
                            <div className='tabs-container'>                           
                                    
                                {(Object.values(isLoadingEntries).some(value => value === true)
                                         || isGeneratingQuestions || isTranscribingAudio || isGeneratingAnswerLLM) ? 
                                    (
                                        <button className='spinner-container-button'><span className='spinner'/></button>
                                    ) : (
                                    <button
                                        className = {activeTab === "home" ? 'active' : 'inactive'}
                                        onClick = {() => setActiveTab('home')}
                                    >
                                        <FontAwesomeIcon icon={faHome} /> 
                                    </button>
                                    )
                                }



                                {sessionToken && encriptionKey && <button
                                    className = {activeTab === "today" ? 'active' : 'inactive'}
                                    onClick = {() => setActiveTab('today')}
                                ><span><FontAwesomeIcon icon={faFeather}/></span>
                                </button>}

                                {sessionToken && encriptionKey && <button
                                    className = {activeTab === "question" ? 'active' : 'inactive'}
                                    onClick = {() => setActiveTab('question')}
                                ><span><FontAwesomeIcon icon={faQuestion}/></span>
                                </button>}
                                
                                
                                {sessionToken && encriptionKey && <button
                                    className = {activeTab === "archive" ? 'active' : 'inactive'}
                                    onClick = {() => setActiveTab('archive')}
                                ><span><FontAwesomeIcon icon={faBookBookmark}/></span>
                                </button>} 

                                {sessionToken && encriptionKey && <button 
                                    className = {activeTab === "chat" ? 'active' : 'inactive'}
                                    onClick = {() => setActiveTab('chat')}    
                                > <FontAwesomeIcon icon={faComment}/>
                                </button>}   
                                
                                {sessionToken && encriptionKey && <button 
                                    className = {activeTab === "models" ? 'active' : 'inactive'}
                                    onClick = {() => setActiveTab('models')}    
                                ><span><FontAwesomeIcon icon={faPerson}/></span>
                                </button>}  

                                {sessionToken && isBetaUser && <button
                                        className = {activeTab === "stats" ? 'active' : 'inactive'}
                                        onClick = {() => setActiveTab('stats')}
                                    ><FontAwesomeIcon icon={faChartSimple} />
                                </button>}

                                {sessionToken && <button
                                        className = {activeTab === "settings" ? 'active' : 'inactive'}
                                        onClick = {() => setActiveTab('settings')}
                                    ><FontAwesomeIcon icon={faCog} />
                                </button>}

                                {!sessionToken && <button 
                                className = {activeTab === "login" ? 'active' : 'inactive'}
                                onClick = {() => setActiveTab('login')}    
                                ><span><FontAwesomeIcon icon={faPersonWalkingArrowRight}/></span>
                                </button>}           
                            </div> 
                            } 
             
                        
                            {activeTab === 'today' && 
                            <>
                                    {isSearchingToday ? (
                                    <SearchBarMessages getSearchResults={getSearchResults} searchTerm={searchBarQuery} setSearchTerm={setSearchBarQuery} setIsSearchingToday={setIsSearchingToday} isSearchingToday={isSearchingToday} IsLoadingEmbeddingSearch={IsLoadingEmbeddingSearch} setSearchResultsList={setResultsList} isDarkMode={isDarkMode} go_to_date={go_to_date} isModalAdvancedSearchVisible_msg={isModalAdvancedSearchVisible_msg} setIsModalAdvancedSearchVisible_msg={setIsModalAdvancedSearchVisible_msg} availableDatesListRefMsg={availableDatesListRefMsg} fetch_dates_msg={fetch_dates_msg} setFilterSearchSelectedTimeDelta={setFilterSearchSelectedTimeDelta} filterSearchSelectedTimeDelta={filterSearchSelectedTimeDelta}
                                    />
                                    ) : (
                                        <div className='tab-search-button'>
                                        <button onClick={()=>{setIsSearchingToday(true)}}><span><FontAwesomeIcon icon={faMagnifyingGlass}/></span></button>
                                        </div>
                                    )}
                                </>
                            }

                            {activeTab === 'models' && 
                                <div>
                                    {isSearchingModels ? (
                                        <div className='search-models-container'>
                                        <input
                                        className={`search-bar-models${isDarkMode ? '-dark' : ''}`}
                                        onChange = {(e)=> {setIsSearchModelTerm(e.target.value)}}
                                        value={isSearchModelTerm}
                                        placeholder='model name'
                                        ref={searchBarModelRef}
                                        />
                                        <button onClick={()=>{setIsSearchingModels(false)
                                                                setIsSearchModelTerm('')}}><FontAwesomeIcon icon={faXmark}/></button>
                                        </div>
                                    ) : (
                                        <div className='tab-search-button'>
                                        <button onClick={()=>{
                                            setIsSearchingModels(true);
                                            setTimeout(() => {
                                                searchBarModelRef.current && searchBarModelRef.current.focus();
                                            }, 300);
                                            }}><span><FontAwesomeIcon icon={faMagnifyingGlass}/></span></button>
                                        </div>
                                    )}
                                </div>
                            
                            
                            }

                                           
                    </div>
                    {isBetaUser && 
                    <div>
                        {Object.values(isLoadingEntries).some(value => value === true) &&<p>isLEntries</p>}
                        {isGeneratingQuestions && <p>isGeneratingQuestions</p>}
                        {isTranscribingAudio && <p>isTranscribingAudio</p>}
                        {isGeneratingAnswerLLM && <p>isGeneratingAnswerLLM</p>}
                    </div>}
                    {!sessionToken || !encriptionKey && (
                            <div className='login-and-enc-key-warning'>

                                    <ul>
                                        {!sessionToken && <li>Login to continue <span> <FontAwesomeIcon icon={faTriangleExclamation} style={{fontSize: '20pt'}}/></span></li>}
                                        {sessionToken && !encriptionKey && 
                                        <li>Input your encription key to see something                                                                     <span> <FontAwesomeIcon icon={faTriangleExclamation} style={{fontSize: '20pt'}}/></span></li>}
                                    </ul>

                            </div>
                        )
                    } 
                </div>
            </div>

            {activeTab === 'settings' && 
                <div className='settings-container'>                    
                    <h1>Settings</h1>
                    <h3>Transcriptions</h3>
                    {false && <SliderSwitch boolStateVar={saveAudioBool} switchStateVarSetter={setSaveAudioBool}>Save audio files</SliderSwitch>}
                    <LanguageSelector languagesOptions={['english', 'català', 'español', 'german', 'chinese']}selectedLanguage={selectedLanguage} setLanguageState={setSelectedLanguage} isDarkMode={isDarkMode}>Language: </LanguageSelector>
                    <h3>Style</h3>
                    <SliderSwitch boolStateVar={isDarkMode} switchStateVarSetter={setIsDarkMode}>Toggle dark mode</SliderSwitch>
                    <FontSelector onSetFont={onFontChange} isDarkMode={isDarkMode}/>
                    <h3>General</h3>
                    <div className='paralel-label-input'>
                        <label>Log out</label>
                        <button onClick={()=>logout()}><FontAwesomeIcon icon={faPersonWalkingArrowLoopLeft}/></button>
                    </div>

                </div>
            } 
            {activeTab === "home" && 
                <div id = "home">
                    {sessionToken && 
                        <div className='header-padding'>
                            <h1>Welcome back {sessionToken && username.split(" ")[0]}!</h1>
                            <h3>Streak: {streak} days!🔥</h3>
                            
                        </div>
                    }
                        {sessionToken && !encriptionKey &&
                        <>
                        <h3>Your key is needed!</h3>
                        <div className='key-test-container'>
                            <div>
                                Key: 
                                <input 
                                type = 'password'
                                className= {isDarkMode ? 'enc-key-input input-dark' : 'enc-key-input'} 
                                value={tentaiveKey}
                                ref={keyHolderRef}
                                onChange={(e) => setTentaiveKey(e.target.value)}
                                />
                            </div>
                            <div className='item-button-pair'>
                                {encriptionKey ? ' ' : ' invalid format'}
                                {encriptionKey ? <FontAwesomeIcon icon={faCheck} style={{color: 'green',  fontSize: '22pt'}}/> : <FontAwesomeIcon icon={faTimes} style={{color: 'red', fontSize: '22pt'}}/>}
                                <button onClick={()=>onTestKeySetting()}><FontAwesomeIcon icon={faKey}></FontAwesomeIcon>   test</button>
                            </div>
                        </div>
                        </>
                    }
                </div>
            }

            {activeTab === 'stats' && 
            <div className='user-info-container'>
            {Object.keys(usersInfo).map((k) => (
                <div key={k}>
                    <h4>{k}</h4>
                    <p>Streak: {usersInfo[k].streak}</p>
                    <div>
                        {Object.keys(usersInfo[k].info).map(day => (
                            <p key={day}><b>{day}</b>: {usersInfo[k].info[day]}</p>
                        ))}
                    </div>
                </div>
            ))}
            </div>


            }

            {activeTab === "archive" && sessionToken && sessionToken && encriptionKey && 
                            <div id = "archive" className='archive-container'>
                                <div className={isDarkMode ? 'tab-menu tab-menu-dark archive' :  
                                'tab-menu archive'}>
                                    <button className = {aActiveTab === 'daily' ? 'active': 'inactive'} 
                                    onClick={() => setAActiveTab('daily')}>Daily</button>
                                    <button className = {aActiveTab === 'weekly' ? 'active': 'inactive'} 
                                    onClick={() => setAActiveTab('weekly')}>Weekly</button>
                                    <button className = {aActiveTab === 'monthly' ? 'active': 'inactive'} 
                                    onClick={() => setAActiveTab('monthly')}>Monthly</button>
                                    {aActiveTab === 'daily' && 
                                    
                                    <MyDatepicker availableDatesListRefMsg={availableDatesListRefArch} fetch_dates_msg={fetch_dates_arch} tag={'archive'} isDarkMode={isDarkMode} goToDate={go_to_date_archive}/>
                                    }
                                
                                </div>
            
                                <ReactModal
                                isOpen={isModalCheckUpdatesOpen}
                                onRequestClose={() => setIsModalCheckUpdatesOpen(!isModalCheckUpdatesOpen)}
                                className = {isDarkMode ? 'modal dark-modal' : 'modal'}
                                overlayClassName = 'modal-overlay-dark'
                                >
                                    <div>A summary can be generated for the entries of the following days, select the ones that you would like to generate: </div>
                                    <p>(For now generating summary and bullet points for all days)</p>
                                    <button onClick = {() => generateSummaries()}>OK</button>
                                    <button onClick = {() => {
                                        setIsModalCheckUpdatesOpen(!isModalCheckUpdatesOpen);
                                        setUnfinishedAGeneration("");
                                        setGeneratedAContent([]);
                                    }}>Close</button>

                                    <div className='generated-content'>
                                        {generatedAContent.map((data, idx) => {
                                            return ( // Return a valid JSX element
                                                <div key={idx}>
                                                    <p><strong>{data.date}, {data.tag}: </strong></p>
                                                    {data.current ? parse(customMdToHtml(data.current)) : parse(unfinishedAGeneration)}
                                                </div>
                                            );
                                        })
                                        }
                                    </div>
                                </ReactModal> 

                                <ReactModal
                                isOpen={isModalGenerationAOpen}
                                onRequestClose={() => setIsModalGenerationAOpen(!isModalGenerationAOpen)}
                                className={isDarkMode ? 'modal dark-modal' : 'modal'}
                                overlayClassName = 'modal-overlay-dark'
                                >
                                    <div className='title-and-buttons'>
                                    <button onClick={onGoToEntryAfterGeneration}> Go to entry</button>
                                    <button className='edit-button close-button' onClick = {onCloseEntryAfterGeneration}><span><FontAwesomeIcon icon={faXmark}/></span></button>
                                    </div>
                                    <div className='generated-content'>
                                        <h2>{generatedAContent[0] && generatedAContent[0].date}</h2>
                                        {generatedAContent.map((data, idx) => {
                                            return ( // Return a valid JSX element
                                                <div key={idx}>
                                                    <h3><strong>{data.tag}:</strong></h3>
                                                    {
                                                        data.tag === 'Takeaways' ? (
                                                        data.current ? parse(customMdToHtml(data.current)) : parse(unfinishedAGeneration)
                                                        ) : (
                                                        parse(replaceBrakeLines(data.current ? data.current : unfinishedAGeneration))
                                                        )
                                                    }
                                                    
                                                </div>
                                            );
                                        })
                                        }
                                    </div>
                                </ReactModal>
                                
                                {aActiveTab === 'daily' && 
                                <div className = 'arch_msgs'>
                                    {Object.values(isLoadingEntries).every(value => value === false) && archEntries.length === 0 && <div style={{padding: '20px'}}>
                                        <p>No entries in archive daily. If it is your first day, come back tomorrow to generate content!</p>
                                            </div>}
                                    
                                    {archEntries.map((entry, idx) => (
                                        <div key={entry.date} ref={archDivRefs.current[idx]}>
                                            <ArchiveDayComponent entry={entry} idx={idx} isDarkMode={isDarkMode} showArchiveEntry={showArchiveEntry} generate_text_daily={generate_text_daily} sessionToken={sessionToken} focusImg={focusImg}/>
                                        </div>
                                    ))
                                    }
                                </div>
            
                                }

                                {
                                    aActiveTab === 'weekly' && 
                                    <div className='arch_msgs'>
                                        {archWEntries.length === 0 && <div style={{paddingTop: '20px'}}><p>No entries in archive weekly yet. Come back next week!</p></div>}
                                        {archWEntries.map((entry, idx) => (
                                        
                                            <div key={entry.year_cw}>
                                                <div className="title-button-container">
                                                    <h3
                                                    className={`title-clickable ${isDarkMode ? 'title-clickable-dark' : ''}`}
                                                    onClick={() => showArchiveEntry(idx)}
                                                    ><strong>Week: {`${entry.cw}, ${entry.year}`}</strong></h3>
                                                    {entry.update_available && (
                                                        <button
                                                        className='miniButton-title'
                                                        hover-text='Updates available for this week. Click to generate!'
                                                        onClick={()=>{generate_text_weekly(entry.year_cw, idx); console.log(entry)}}
                                                        >
                                                            <span>
                                                                <FontAwesomeIcon icon={faRotateRight} />
                                                            </span>
                                                        </button>
                                                    )
                                                    }
                                                </div>
                                                <div>{parse(entry.bullets_html)}</div>
                                                {/* <hr/> */}
                                            </div>
                                            )
                                        )}
                                    </div>

                                }

                                {
                                    aActiveTab === 'monthly' &&
                                    <div className='arch_msgs'>
                                        {archWEntries.length === 0 && <div style={{paddingTop: '20px'}}><p>No entries in archive month yet.</p></div>}
                                        {archMEntries.map((entry, idx) => (
                                        
                                            <div key={entry.year_month}>
                                                <div className="title-button-container">
                                                    <h3
                                                    className={`title-clickable ${isDarkMode ? 'title-clickable-dark' : ''}`}
                                                    onClick={() => showArchiveEntry(idx)}
                                                    ><strong>{`${entry.month_name}, ${entry.year_month.split('_')[0]}`}</strong></h3>
                                                                                                                                                   {entry.update_available && (
                                                        <button
                                                        className='miniButton-title'
                                                        hover-text='Updates available for this month. Click to generate!'
                                                        onClick={()=>{generate_text_monthly(entry.year_month, idx)}}
                                                        >
                                                            <span>
                                                                <FontAwesomeIcon icon={faRotateRight} />
                                                            </span>
                                                        </button>
                                                    )
                                                    }   
                                                </div>
                                                <div>{parse(entry.bullets_html)}</div>
                                                {/* <hr/> */}
                                            </div>
                                            )
                                        )}
                                    </div>
                                }

                                    <ReactModal
                                            isOpen = {isModalAOpen}
                                            onRequestClose = {() => setIsModalAOpen(false)}
                                            contentLabel = "Edit Archive entry"
                                            className = {isDarkMode ? 'arch-modal dark-modal' : 'arch-modal'}
                                            overlayClassName = 'modal-overlay-dark'
                                    >
                                    <div className='modal-content-container arch-content-container' style={fontSizeRef.current}>
                                    
                                        {
                                        // Deleting entry modal
                                        <RemoveEntryModal 
                                        isDeletingEntry={isDeletingEntry} 
                                        deleteFunction={()=>onDeleteEntryArchive()} 
                                        setIsDeletingEntry={setIsDeletingEntry}
                                        isDarkMode={isDarkMode}
                                        />

                                        }
                                        { aActiveTab === 'daily' && 
                                            <div className='content-archive-open'>
                                                <div className='title-and-buttons'>
                                                    <h4>{editingArchEntry.date}: {editingArchEntry.title}</h4>
                                                    <div className='vertically-stacked-buttons'>
                                                        <div className='title-and-buttons'>
                                                            <button type="button" className="edit-button" onClick={()=>hiddenUploadFileAInput.current.click()}><span><FontAwesomeIcon icon={faPaperclip}/></span></button>
                                                            <button className="edit-button" onClick={() => setIsDeletingEntry(true)}><span><FontAwesomeIcon icon={faTrashCan}/></span></button>
                                                            <button className='edit-button close-button' onClick={()=>{setIsModalAOpen(false)}}><span><FontAwesomeIcon icon={faXmark}/></span></button>
                                                            <input type="file"
                                                                // how to select the text displayed in the button
                                                                onChange={handleImageChangeArchive}
                                                                accept="image/*" 
                                                                style={{display: 'none'}}
                                                                ref={hiddenUploadFileAInput}
                                                            ></input>
                                                        </div>
                                                        <button onClick={()=>{generateFollowUpQuestionsArch();setIsModalAOpen(false)}}>Prompt me</button>
                                                    </div>
                                                </div>                                                    
                                                {editingArchEntry.images && 
                                                // Applying minHeight because the images might still be loading when the container calculates its size based on its content (setting to zero).
                                                <div className='displayed-imgs' style={{'minHeight': editingArchEntry.images ? '180px' : '0px'}}>
                                                        {editingArchEntry.images.split(",").map((imgname) => {
                                                            
                                                            return (
                                                            <div className='img-closebutton-wrapper'>
                                                                    <button 
                                                                    className='close-button-small'
                                                                    onClick={()=>removeDisplayedImg(imgname)}><span><FontAwesomeIcon icon={faXmark}/></span></button>
                                                                    <div className={isDarkMode ? 'cute-img-container cic-dark' : 'cute-img-container'} 
                                                                    key={imgname}> 
                                                                        <ImageComponent 
                                                                            imageName={imgname} 
                                                                            token={sessionToken}
                                                                            maxResolution={'150px'}
                                                                            handleClick={focusImg}
                                                                        />
                                                                    </div>
                                                            </div>
                                                            );
                                                        })
                                                        }                                 
                                                </div>
                                                }


                                                    <OnFocusEntry
                                                    tag_category={'bullets_html'}
                                                    title={'Takeaways'}
                                                    onSubmitEdit={handleSubmitEditAEntry}
                                                    />
                                                    
                                                    <OnFocusEntry 
                                                    tag_category={'summary'} 
                                                    title={'Summary'}
                                                    onSubmitEdit={handleSubmitEditAEntry}
                                                    />

                                                    <OnFocusEntry
                                                    tag_category={'reworked_entry'}
                                                    title={'Reworked entry'}
                                                    onSubmitEdit={handleSubmitEditAEntry}
                                                    />

                                                    <OnFocusEntry
                                                    tag_category={'raw_entry'}
                                                    title={"Entry"}
                                                    onSubmitEdit={handleSubmitEditAEntry}
                                                    />
                                            </div>
                                        }

                                        {
                                            aActiveTab === 'weekly' && 
                                            <div>
                                                <div className='title-and-buttons'>
                                                <h4>Week {editingArchEntry.cw}, {editingArchEntry.year}</h4>
                                                <button className='edit-button' onClick={() => setIsDeletingEntry(true)}><span><FontAwesomeIcon icon={faTrashCan}/></span></button>
                                                <button className='edit-button close-button' onClick={()=>setIsModalAOpen(false)}><span><FontAwesomeIcon icon={faXmark}/></span></button>
                                                </div>
                                                <OnFocusEntry
                                                tag_category={'bullets_html'}
                                                title = {'Takeaways'}
                                                onSubmitEdit={handleSubmitEditAEntry}
                                                />

                                                
                                                <OnFocusEntry
                                                tag_category={'summary'}
                                                title = {'Summary'}
                                                onSubmitEdit={handleSubmitEditAEntry}
                                                />
                                            
                                            </div>
                                        }  

                                        {
                                            aActiveTab === 'monthly' &&
                                            <div>
                                                <div className='title-and-buttons'>
                                                    <h4>Month {editingArchEntry.month_name}, {editingArchEntry.year}</h4>
                                                    <button className='edit-button' onClick={()=> setIsDeletingEntry(true)}><span><FontAwesomeIcon icon={faTrashCan}/></span></button>
                                                    <button className='edit-button close-button' onClick={()=>setIsModalAOpen(false)}><span><FontAwesomeIcon icon={faXmark}/></span></button>
                                                </div>
                                                <OnFocusEntry
                                                tag_category={'bullets_html'}
                                                title = {'Takeaways'}
                                                onSubmitEdit={handleSubmitEditAEntry}
                                                />

                                                
                                                <OnFocusEntry
                                                tag_category={'summary'}
                                                title = {'Summary'}
                                                onSubmitEdit={handleSubmitEditAEntry}
                                                />

                                            </div>
                                        }

                                    </div>
                                </ReactModal>
                            </div>
                        }

            {activeTab === "login" && 
            // <LoginForm/>
            <div id="login" className='google-login'>
                <GoogleLogin
                    onSuccess={credentialResponse => {

                        fetch(`${fetch_url}/login`, {
                            method: "POST",
                            headers: {"Content-Type": "application/json"},
                            body: JSON.stringify({token: credentialResponse.credential})
                        })
                        .then(response => response.json())
                        .then(data => {
                            setSessionToken(data.token);
                            setUserEmail(data.email);
                            setIsBetaUser(data.isBetaUser);
                            setUsername(data.name);
                            setStreak(data.streak);
                            setActiveTab('home');
                        });  

                    }}
                    onError={() => {
                        console.log('Login Failed');
                    }}
                />
            </div>
            }
            
            {activeTab === 'question' && sessionToken && encriptionKey &&
            <div>
                <QuestionsSlider 
                    followUpsQuestions={followUpQuestions}
                    setFollowUpQuestions={setFollowUpQuestions} 
                    isMobile={isMobile}
                    onAnswerQuestion = {onAnswerFollowUpQuestion}
                    onRemoveFollowUpQuestion={removeFollowUpQuestion}
                    isDarkMode={isDarkMode}
                    displayedInfoFollowUpMessage = {displayedInfoFollowUpMessage}
                    markQuestionAsSeen={markQuestionAsSeen}
                    />            
            </div>
            
            }

            {(activeTab === "today" || activeTab === "archive") &&
            <>
                <ReactModal
                isOpen = {isModalPicturesOpen}
                onRequestClose = {() => setIsModalPicturesOpen(false)}
                className = {`modal-img-container ${isDarkMode ? 'dark-modal' : ''}`}
                overlayClassName = 'modal-overlay-dark'
                ref={modalImgHolderTodayRef}
                >   
                    <div className='modal-content-container' onClick={(e) => setIsModalPicturesOpen(false)}>
                        <img src={imgSrcModal} onClick={(e) => e.stopPropagation()}/>
                    </div>
                </ReactModal>
                <div style={{height: '1px'}}/>

                <ReactModal
                isOpen={isAnalyzingEntry}
                onRequestClose = {onCloseModalAnalysis}
                className = {isDarkMode ? 'modal dark-modal' : 'modal'}
                overlayClassName = 'modal-overlay-dark'
                >
                    <div className='title-and-buttons single-button'>
                        <CloseButton onClick={onCloseModalAnalysis}/>
                    </div>
                    {parse(analyzedData)}
                    
                </ReactModal>
            </>
            }
            

            {activeTab === "today" && sessionToken && encriptionKey && 
                <div className="today">

                        <div className='messages_container' ref = {chatMessagesRef}>
                                {
                                messages.map((message, index) => (
                                    <MessageComponent
                                        message={message}
                                        removeEntry={removeEntry}
                                        index={index}
                                        sessionToken={sessionToken}
                                        focusImg={focusImg}
                                        isDarkMode={isDarkMode}
                                        handleEditMessage={handleEditMessage}
                                        models = {modelsList}
                                        linkModel = {linkModel}
                                        addNewModel = {addNewModel}
                                        removeAliasFromEntry = {removeAliasFromEntry}
                                        analyzeText = {analyzeJournalEntry}
                                        generateFollowUpQuestions = {generateFollowUpQuestions}
                                        isGeneratingQuestions = {isGeneratingQuestions}
                                        isMobile={isMobile}
                                        isIOS={isIOS}
                                        idxToDeleteRef={idxToDeleteRef}
                                        removeTextHintRef={removeTextHintRef}
                                        setIsDeletingMessage={setIsDeletingMessage}
                                        setDateOnMessage={setDateOnMessage}
                                        replaceByLongDash={replaceByLongDash}
                                        isBetaUser = {isBetaUser}
                                        userEmail={userEmail}
                                        handlePaste={handlePaste}
                                        // assigning the ref directly makes it available as ref_name.current both inside and outside the component. 
                                        ref={messagesRefComponentsList.current[index]}
                                    />
                                ))
                                }
                            <div ref={messagesEndRef}/>
                        </div>
                        {resultsList.length > 0 && <SearchResults results={resultsList} go_to_entry={go_to_entry} isDarkMode={isDarkMode}/>}

                    
                    {!isScreenAtBottom && <button className={isDarkMode ? 'teleport-bottom-button tp-dark' : 'teleport-bottom-button'} onClick={()=>scrollToBottom()}><FontAwesomeIcon icon={faArrowDown} /></button>}

            </div>
            }
            {activeTab === 'today' && 
            <RemoveEntryModal 
                isDeletingEntry={isDeletingMessage} 
                setIsDeletingEntry={setIsDeletingMessage}
                deleteFunction={()=>removeEntry(idxToDeleteRef.current)}
                text={removeTextHintRef.current}
                isDarkMode={isDarkMode}
            />
            }      
            {activeTab === 'today' && encriptionKey && <CustomTextInput 
                handleSendMessage = {addEntry} 
                handleImageChange = {handleImageChange} 
                handleToggleAudio = {handleToggleAudio} 
                isRecordingAudio = {isRecordingAudio}
                textInputRef = {textEntryRef}
                imagesPreviewRef = {imgsPreviewContainerRef}
                chatMessagesRef={chatMessagesRef}
                attachedImgs = {attachedImgs}
                removeImage = {removeImg}
                handlePaste={handlePaste}
                questions_list={['What am I grateful for today?',
                                'What have I learned today?',
                                'What am I looking forward to tomorrow?',
                                'What could I have done differently today to enjoy the process more?',
                                'What have I given today?'
                                ]}
                isDarkMode={isDarkMode}
                setMessagesHeight={setMessagesHeight}
                isTranscribingAudio={isTranscribingAudio}
                followUpsQuestions={followUpQuestions}
                questionBeingAnswered={questionBeingAnswered}
                setQuestionBeingAnswered={setQuestionBeingAnswered}
                isMobile={isMobile}
                replaceByLongDash={replaceByLongDash}
                />
            }
                                

            {activeTab === "chat" && sessionToken && encriptionKey && 
            <div>
                <ChatEntries 
                    user={username} 
                    socket_ref={socket_ref}
                    user_token={sessionToken}
                    isDarkMode={isDarkMode}
                    encriptionKey={encriptionKey}
                    setIsGeneratingAnswerLLM={setIsGeneratingAnswerLLM}
                >
                </ChatEntries>
                
            </div> }

            {activeTab === "models" && sessionToken &&                 
            
            <div className='models-global-container'>
                <ReactModal
                    isOpen = {isAddingNewModel}
                    onRequestClose = {() => setIsAddingNewModel(false)}
                    className = {isDarkMode ? 'modal dark-modal' : 'modal'}
                    overlayClassName = 'modal-overlay-dark'
                    
                >
                    <div className='models-add-new'>
                        <CloseButton onClick={() => setIsAddingNewModel(false)}/>
                        <div>
                            <h4>Full name:</h4>
                                <input type='text' 
                                className= {isDarkMode ? 'input-dark' : ''} 
                                placeholder='Beff Jezos' 
                                value={newModelName} 
                                onChange={(e) => setNewModelName(e.target.value)}/>
                        </div>
                        <div>
                            <h4>Alias:</h4>
                            <input type='text'
                            className= {isDarkMode ? 'input-dark' : ''}  
                            onChange={(e)=>setCurrentAlias(e.target.value)}
                            value={currentAlias}
                            placeholder='beff J'/>
                            <button onClick={()=>addAliasToList(currentAlias)}>Add alias</button>
                            <button onClick={() => setNewModelAliasList([])}><FontAwesomeIcon icon={faTrashCan}/></button>
                            <p style={{fontSize: '0.9em'}}>Alias: { newModelAliasList.join(', ')}</p>    
                        </div>
        
            
                        <h4>Description:</h4>
                        <div
                            className={`input-description ${isDarkMode ? 'input-dark' : ''}`}
                            contentEditable='true'
                            role='textbox'
                            aria-multiline='true'
                            ref={newModelDescriptionRef}
                            onKeyDown={simpleHandleKeyDownDesc}
                        />    
                        <button onClick={(addModelInfo)}>Add model</button>
                    </div>
                </ReactModal>
                <button className='button-add-models' onClick={()=>setIsAddingNewModel(true)}>Add a <FontAwesomeIcon icon={faPerson} /> that matters</button>
                
                {
                !isSearchingModels ? (
                modelsList.map((model, idx) => (
                    <div key={model.id}>
                    <ModelComponent
                    idx={idx}
                    model={model}
                    editModel={editModel}
                    removeModel={removeModel}
                    isDarkMode={isDarkMode}
                    replaceByLongDash={replaceByLongDash}
                    />
                    </div>
                ))
                ) : (

                modelsList.filter( m => m.name.toLowerCase().trim().includes(isSearchModelTerm.trim().toLowerCase())).map((model, idx) => (
                    <div key={model.id}>
                    <ModelComponent
                    idx={idx}
                    model={model}
                    editModel={editModel}
                    removeModel={removeModel}
                    isDarkMode={isDarkMode}
                    replaceByLongDash={replaceByLongDash}
                    />            
                    </div>
                ))
                )
                }
            </div>
            }
        </div>
    );
};



export default ChatApp;
